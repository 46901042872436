import React, {useEffect, useState} from 'react';
import {I18n} from 'react-redux-i18n';
import {useDispatch, useSelector} from 'react-redux';
import {WithStyles, withStyles} from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import {Refresh} from '@material-ui/icons';

import {CustomButton, CustomInput} from '../../material-dashboard-pro-react/components';
import Autocomplete from '../Autocomplete/Autocomplete';
import {ConfiguredGroups, NotifyModalWindow, ObservedGroups, RenderOrEmpty} from '../index';
import {RightAvailability, RightAvailabilityOrWarn, RegisterEntraAppButton} from '../../containers';

import {
  ACCESS_RIGHTS,
  DATE_FORMAT,
  ORGANIZATION_OPERATOR_PERMISSIONS,
  STATES_ENTITY,
  USER_ROLES,
} from '../../constants';
import style from './style';
import {AppStoreType} from '../../utils/store';
import {getLoggedAccount} from '../../utils/account';
import {
  bindTenant,
  getGroupsAndSyncTimeByTenant,
  setUpdatedEntraConfiguredGroups,
} from '../../containers/PersonasEditForm/action';
import {formatDateTimeToUserTimezone} from '../../utils/dateTime';
import {
  getTenants,
  syncEntraGroups,
} from '../../containers/UsersConfigurer/action';

type IdName = {
  id: string;
  name: string;
};

type ConfiguredGroup = {
  id?: string;
  name: string;
  policyId: string;
};

type EntraIdTabProps = WithStyles<typeof style> & {
  tenants: IdName[];
  isEditMode: boolean;
  showDeniedBindButtons?: boolean;
}

const PERSONA_REFRESH_INTERVAL_MS = 30000;

/**
 * A tab with settings of persona's entra ID
 * @param {classes} classes - MUI style classes object
 * @param {tenants} tenants - string array of available persona's tenants from Entra.
 * Each policy must have `name` and `id` fields
 * @param {isEditMode} isEditMode - if true, data can be edited
 * @param {showDeniedBindButtons} showDeniedBindButtons - if true, bind/unbind buttons will be shown
 * even though they are denied for current user
 */
function EntraIdTab({
  classes,
  tenants,
  isEditMode,
  showDeniedBindButtons = true,
}: EntraIdTabProps) {
  const entityType = 'persona';

  const accessRights = {
    bindPersona: [ACCESS_RIGHTS.PERSONA_BIND],
    unbindPersona: [ACCESS_RIGHTS.PERSONA_BIND],
  };
  const limitAccessForBindButtonsFor = [
    {
      role: USER_ROLES.ORGANIZATION_OPERATOR,
      permission: ORGANIZATION_OPERATOR_PERMISSIONS.MANAGER,
    },
  ];

  const accountId = getLoggedAccount()?.accountId ?? '';
  const bindedTenantId =
    useSelector((store: AppStoreType) => store.personaEditFormReducer.entraId.entraTenantId);
  const bindedTenantName =
    useSelector((store: AppStoreType) => store.personaEditFormReducer.entraId.entraTenantName);
  const observedGroups: IdName[] =
    useSelector((store: AppStoreType) => store.personaEditFormReducer.entraId.entraObservedGroups);
  const configuredGroups: ConfiguredGroup[] = useSelector(
    (store: AppStoreType) => store.personaEditFormReducer.entraId.entraConfiguredGroups,
  );
  const policies: IdName[] = useSelector((store: AppStoreType) => store.policyReducer.policies);
  const lastSyncAt = useSelector(
    (store: AppStoreType) => store.personaEditFormReducer.tenantLastSyncAt,
  );
  const selectedPersonaId = useSelector(
    (store: AppStoreType) => store.personaReducer.selectedPersona.id,
  );
  const personaState = useSelector((store: AppStoreType) => store.personaReducer.personaState);
  const personaBaseSetting
    = useSelector((store: AppStoreType) => store.personaEditFormReducer.baseSettingsObject);

  const [tenantToBind, setTenantToBind] = useState({id: '', name: ''});
  const [tenantDomain, setTenantDomain] = useState('');
  const [creatingGroup, setCreatingGroup] = useState(false);
  const [modalWindowOpened, setModalWindowOpened] = useState(false);

  const dispatch = useDispatch();

  const labelProps = {
    shrink: true,
  };

  const onGroupsSyncClick = () => {
    syncEntraGroups(bindedTenantId)(dispatch);
  };

  const handleLocalTenantChange = (newTenant: { value?: string; label?: string }) => {
    setTenantToBind({id: newTenant.value ?? '', name: newTenant.label ?? ''});
  };

  const handleTenantUnbinding = () => {
    (bindTenant({}))(dispatch);
    handleLocalTenantChange({});
  };

  const refreshTenants = () => {
    (getTenants())(dispatch);
  };

  const updateConfiguredGroups = (newGroups: ConfiguredGroup[]) => {
    dispatch(setUpdatedEntraConfiguredGroups(newGroups) as never);
  };

  const formEntraAddTenantState = () => {
    const personaIdToRedirect = personaState === STATES_ENTITY.CREATING
      ? ''
      : selectedPersonaId ?? '';
    return JSON.stringify({
      accountId,
      personaId: personaIdToRedirect,
      name: personaBaseSetting.name,
      description: personaBaseSetting.description,
    });
  };

  const handleTenantBinding = () => {
    dispatch(bindTenant(tenantToBind) as never);
    dispatch(getGroupsAndSyncTimeByTenant(tenantToBind.id) as never);
    updateConfiguredGroups([]);
  };

  const renderDenyModalWindow = () => (
    <RenderOrEmpty isShow={modalWindowOpened}>
      <NotifyModalWindow
        text={I18n.t(
          'modalWindow.forbidAction',
          {
            entityAction: 'change Entra ID tenant for',
            entityType,
          },
        )}
        close={() => setModalWindowOpened(false)}
      />
    </RenderOrEmpty>
  );

  const getButtonBind = () => {
    if (!showDeniedBindButtons) {
      return (
        <RenderOrEmpty isShow={isEditMode}>
          <IconButton
            onClick={refreshTenants}
            size="small"
          >
            <Refresh />
          </IconButton>
          <CustomButton
            color="secondary"
            customClasses="uppercase"
            onClick={handleTenantBinding}
            disabled={!tenantToBind.id}
          >
            {I18n.t('users.usersConfigurer.persona.bind')}
          </CustomButton>
        </RenderOrEmpty>
      );
    }
    return (
      <>
        <RightAvailabilityOrWarn
          accessRights={accessRights.bindPersona}
          onClickDeny={() => setModalWindowOpened(true)}
          limitDenyFor={limitAccessForBindButtonsFor}
        >
          <IconButton
            onClick={refreshTenants}
            size="small"
          >
            <Refresh />
          </IconButton>
          <CustomButton
            color="secondary"
            customClasses="uppercase"
            onClick={handleTenantBinding}
            disabled={!tenantToBind.id}
          >
            {I18n.t('users.usersConfigurer.persona.EntraId.bind')}
          </CustomButton>
        </RightAvailabilityOrWarn>
        {renderDenyModalWindow()}
      </>
    );
  };

  const getAddTenantBlock = () => (
    <div className={classes.addTenantBlockContainer}>
      <CustomInput
        labelText={I18n.t('users.usersConfigurer.persona.tenantDomain')}
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          placeholder: I18n.t('users.usersConfigurer.persona.placeholders.tenantDomain'),
          name: 'domain',
          value: tenantDomain,
          onChange: (e: any) => setTenantDomain(e.target.value),
        }}
      />
      <RegisterEntraAppButton
          appId={process.env.REACT_APP_ENTRA_CLIENT_ID as string}
          redirectUrl={process.env.REACT_APP_ENTRA_REDIRECT_URI as string}
          domain={tenantDomain}
          state={formEntraAddTenantState()}
          disabled={!isEditMode || !accountId}
      />
    </div>
  );

  const beforeBinding = () => (
    <div className={classes.header__addTenantBlock}>
      <div className={classes.header__group}>
        <Autocomplete
          closeMenuOnSelect={true}
          labelText={I18n.t('users.usersConfigurer.persona.entraId.tenant')}
          inputProps={{
            className: classes.dropdown,
            onChange: handleLocalTenantChange,
            options: tenants?.map((t) => ({label: t.name, value: t.id})) ?? [],
            value: {label: tenantToBind.name, value: tenantToBind.id},
          }}
          isDisabled={!isEditMode}
          labelProps={labelProps}
        />
        {getButtonBind()}
      </div>
      {getAddTenantBlock()}
    </div>
  );

  const addObservedGroupToConfigured = (id: string) => {
    setCreatingGroup(false);
    if (!configuredGroups?.some((g) => g.id === id)) {
      updateConfiguredGroups([
        ...configuredGroups,
        {
          name: observedGroups.find((g) => g.id === id)?.name ?? '',
          policyId: policies[0]?.id ?? '',
        },
      ]);
    }
  };

  const addAllObservedGroupToConfigured = () => {
    setCreatingGroup(false);
    const configuredIds = configuredGroups?.map((g) => g.id);
    const notAddedGroups = observedGroups
      .filter((g) => !configuredIds.includes(g.id))
      .map((g) => ({...g, policyId: policies[0]?.id}));
    updateConfiguredGroups([...configuredGroups, ...notAddedGroups]);
  };

  const createManualGroup = (groupName: string) => {
    if (!groupName) {
      setCreatingGroup(false);
    } else if (!configuredGroups.some((g) => g.name?.toUpperCase() === groupName.toUpperCase())
      && !observedGroups.some((g) => g.name?.toUpperCase() === groupName.toUpperCase())
    ) {
      updateConfiguredGroups([
        ...configuredGroups, {name: groupName, policyId: policies[0]?.id},
      ]);
      setCreatingGroup(false);
    }
  };

  const getButtonUnbind = () => {
    if (!showDeniedBindButtons) {
      return (
        <RightAvailability
          key="block-user-action"
          accessRights={accessRights.unbindPersona}
        >
          <CustomButton
            color="secondary"
            customClasses="uppercase"
            onClick={handleTenantUnbinding}
            disabled={!isEditMode}
          >
            {I18n.t('users.usersConfigurer.persona.unbind')}
          </CustomButton>
        </RightAvailability>
      );
    }

    return (
      <>
        <RightAvailabilityOrWarn
          accessRights={accessRights.unbindPersona}
          onClickDeny={() => setModalWindowOpened(true)}
          limitDenyFor={limitAccessForBindButtonsFor}
        >
          <CustomButton
            color="secondary"
            customClasses="uppercase"
            onClick={handleTenantUnbinding}
            disabled={!isEditMode}
          >
            {I18n.t('users.usersConfigurer.persona.unbind')}
          </CustomButton>
        </RightAvailabilityOrWarn>
        {renderDenyModalWindow()}
      </>
    );
  };

  const afterBinding = () => (
    <div>
      <div className={classes.header}>
        <div className={classes.header__group}>
          <p className={classes.header__title}>{bindedTenantName}</p>
          {getButtonUnbind()}
        </div>
        <div className={classes.header__group_a}>
          <CustomButton
            color="primary"
            customClasses="uppercase"
            onClick={onGroupsSyncClick}
            disabled={!isEditMode}
          >
            {I18n.t('users.usersConfigurer.persona.entraId.sync')}
          </CustomButton>
          <p className={classes.header__text}>
            {I18n.t('users.usersConfigurer.persona.entraId.lastSyncAt')}
            {formatDateTimeToUserTimezone(lastSyncAt, DATE_FORMAT.YYYYMMDD_HHmmss)}
          </p>
        </div>
      </div>
      <div className={classes.groupsContainer}>
        <ObservedGroups
          onAddGroupClick={addObservedGroupToConfigured}
          onAddAllClick={addAllObservedGroupToConfigured}
          groups={observedGroups}
          disabled={!isEditMode}
        />
        <ConfiguredGroups
          groups={configuredGroups}
          policies={policies}
          onNewClick={() => setCreatingGroup(true)}
          onAddManualGroup={createManualGroup}
          isCreatingManualGroup={creatingGroup}
          onGroupsChange={updateConfiguredGroups}
          disabled={!isEditMode}
        />
      </div>
    </div>
  );

  useEffect(() => {
    if (bindedTenantId) {
      (getGroupsAndSyncTimeByTenant(bindedTenantId))(dispatch);
    }

    const interval = setInterval(() => {
      if (bindedTenantId) {
        (getGroupsAndSyncTimeByTenant(bindedTenantId))(dispatch);
      }
    }, PERSONA_REFRESH_INTERVAL_MS);
    return () => clearInterval(interval);
  }, [bindedTenantId, dispatch]);

  useEffect(() => {
    if (!isEditMode) {
      setCreatingGroup(false);
    }
  }, [isEditMode]);

  useEffect(() => {
    refreshTenants();
  }, []);

  return (
    bindedTenantId ? afterBinding() : beforeBinding()
  );
}

export default withStyles(style)(EntraIdTab);
