import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {ContainerWithListAndForm,
  PersonaDetails,
  PersonaCreating,
  PersonaTable} from '../../components/index';
import {COMPRESSED_MODE_TABLE, FULL_WIDTH_MODE_TABLE,
  MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM,
  STATES_ENTITY} from '../../constants';
import {
  changeEditModeAndPersonaState,
  createPersona,
  deletePersona,
  getDataToShowPersonaDetails,
  getDomains,
  getPersonasWithUserStats,
  resetSelectedPersonaIndex,
  resetPersonaData,
  updatePersona,
  getPersonaDetailsAfterAddingTenant,
} from './action';
import {getPolicies} from '../../app-common/Policy/action';
import {useAddEntraTenant} from '../../app-common';
import {resetPersonaFormValues, updateBaseSetting} from '../PersonasEditForm/action';

function UsersConfigurer() {
  const dispatch = useDispatch();

  const [tableMode, setTableMode] = useState(FULL_WIDTH_MODE_TABLE);
  const [modeShowingContainer, setModeShowingContainer] =
    useState(MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN);

  const domains = useSelector((store) => store.personaReducer.domains);
  const tenants = useSelector((store) => store.personaReducer.tenants);
  const personas = useSelector((store) => store.personaReducer.personas);

  const selectedPersonaIndex = useSelector((store) => store.personaReducer.selectedPersonaIndex);
  const selectedPersona = useSelector((store) => store.personaReducer.selectedPersona);
  const isEditMode = useSelector((store) => store.personaReducer.isEditMode);
  const personasLoaded = useSelector((store) => store.personaReducer.personasLoaded);

  const baseSettingsObject =
    useSelector((store) => store.personaEditFormReducer.baseSettingsObject);
  const directoryServices = useSelector((store) => store.personaEditFormReducer.directoryServices);
  const entraId = useSelector((store) => store.personaEditFormReducer.entraId);

  const loggedAccount = useSelector((store) => store.userAccountsReducer.loggedAccount);
  const currentOrganization =
    useSelector((store) => store.userOrganizationsReducer.currentOrganization);
  const pageLoading =
    useSelector((store) => store.loadingReducer.page);

  const showCreateNewPersona = (resetValues = true) => {
    dispatch(changeEditModeAndPersonaState(false, STATES_ENTITY.CREATING));
    setModeShowingContainer(MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM);
    if (resetValues) {
      dispatch(resetPersonaFormValues());
    }
  };

  const handlePersonaClick = (personaIndex) => {
    dispatch(getDataToShowPersonaDetails(personas[personaIndex].id, personaIndex));
    dispatch(changeEditModeAndPersonaState(false, STATES_ENTITY.EDITING));
    setModeShowingContainer(
      MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS,
    );
  };

  const handleCancelEdit = () => {
    dispatch(changeEditModeAndPersonaState(false, STATES_ENTITY.EDITING_CANCELED));
  };

  const handleCancelCreating = () => {
    dispatch(changeEditModeAndPersonaState(false, STATES_ENTITY.EDITING_CANCELED));
    setModeShowingContainer(
      MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN,
    );
  };

  const goBackToPersonas = () => {
    dispatch(resetSelectedPersonaIndex);
    setModeShowingContainer(MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN);
  };

  const startPersonaEdit = () => {
    dispatch(changeEditModeAndPersonaState(true, STATES_ENTITY.EDITING));
  };

  const handleCreateNewPersona = () => {
    dispatch(createPersona({...baseSettingsObject, ...directoryServices, ...entraId}));
    setModeShowingContainer(
      MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN,
    );
  };

  const handleSavePersona = () => {
    dispatch(updatePersona({...baseSettingsObject, ...directoryServices, ...entraId}))
      .then((success) => {
        if (success) {
          dispatch(changeEditModeAndPersonaState(false, STATES_ENTITY.NONE));
        }
      });
  };

  const handleDeletePersona = () => {
    dispatch(deletePersona(selectedPersona.id));
    setModeShowingContainer(
      MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN,
    );
  };

  const isValidPersonaInfo = () =>
    !!baseSettingsObject?.name;

  const {state: entraState, complete, clearParams} = useAddEntraTenant();

  useEffect(() => {
    if (!entraState ||
      pageLoading.isLoading ||
      !personasLoaded ||
      !complete
    ) {
      return;
    }
    const statePersonaIndex = personas.findIndex((persona) => persona.id === entraState.personaId);
    const stateBaseSettings = {
      id: entraState.personaId ?? '',
      name: entraState.name ?? '',
      description: entraState.description ?? '',
    };
    if (statePersonaIndex < 0) {
      showCreateNewPersona(false);
      dispatch(updateBaseSetting(stateBaseSettings));
    } else {
      dispatch(getPersonaDetailsAfterAddingTenant(
        personas[statePersonaIndex].id, statePersonaIndex, stateBaseSettings,
      ));
      setModeShowingContainer(
        MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS,
      );
      startPersonaEdit();
    }
    clearParams();
  }, [entraState, personas, complete, pageLoading, clearParams]);

  useEffect(() => {
    setTableMode(
      modeShowingContainer === MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN
        ? FULL_WIDTH_MODE_TABLE
        : COMPRESSED_MODE_TABLE,
    );
  }, [modeShowingContainer]);

  useEffect(() => {
    dispatch(resetPersonaData());
    dispatch(getPersonasWithUserStats());
    dispatch(getDomains());
    return () => {
      dispatch(resetPersonaData());
    };
  }, [loggedAccount, currentOrganization, dispatch]);

  const getForm = () => {
    let containerItem = null;
    switch (modeShowingContainer) {
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_CREATING_FORM:
        containerItem = (
          <PersonaCreating
            handleClickButtonCancel={handleCancelCreating}
            handleClickButtonSave={handleCreateNewPersona}
            getPolicies={getPolicies}
            domains={domains}
            tenants={tenants}
            isValidPersonaInfo={isValidPersonaInfo()}
          />
        );
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS:
        containerItem = (
          <PersonaDetails
            persona={selectedPersona}
            getPolicies={getPolicies}
            domains={domains}
            tenants={tenants}
            handleClickButtonCancel={handleCancelEdit}
            handleClickButtonSave={handleSavePersona}
            handleClickButtonBack={goBackToPersonas}
            handleClickButtonDelete={handleDeletePersona}
            handleClickButtonEdit={startPersonaEdit}
            handleClickButtonCancelEdit={handleCancelEdit}
            isEditMode={isEditMode}
            isValidPersonaInfo={isValidPersonaInfo()}
          />
        );
        break;
      default:
        break;
    }
    return containerItem;
  };

  return (
    <ContainerWithListAndForm
      list={(
        <PersonaTable
          mode={tableMode}
          isEditMode={isEditMode}
          personas={personas}
          handleClickButtonNew={showCreateNewPersona}
          handleClickPersonaRow={handlePersonaClick}
          selectedPersonaIndex={selectedPersonaIndex}
        />
      )}
      form={getForm()}
    />
  );
}

UsersConfigurer.propTypes = {
};

export default UsersConfigurer;
