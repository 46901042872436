import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _get from 'lodash/get';

import {withStyles} from '@material-ui/core/styles';
import _isEqual from 'lodash/isEqual';

import {
  COMPRESSED_MODE_TABLE as COMPRESSED,
  FULL_WIDTH_MODE_TABLE as FULL_WIDTH,
  MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM,
} from '../../constants';
import {
  MIN_TABLE_ROWS,
  LIMIT_LOAD_DATA,
} from '../../app-common/Users/constants';

import {UsersControls} from '../index';
import {
  ContainerWithListAndForm,
  UsersTable,
  RenderOrEmpty,
  DeleteEntityWithCustomButtonsModalWindow,
} from '../../components';
import UserDetails from '../../components/UserDetails/UserDetails';

import {
  loadGroups,
  loadUsers,
  loadMoreUsers,
  resetUsersManagerData,
  setSearchOption,
  resetFilters,
  getADUserInfo,
  getDataToShowUserDetails,
  resetSelectedUserIndex,
  forgetUsersByIdList,
  forgetUserById,
} from './action';
import {getPersonasNames} from '../UsersConfigurer/action';

import style from './style';

class UsersManager extends React.Component {
  static initState = {
    modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN,
    tableMode: FULL_WIDTH,
    prevSearchOptions: {},
    prevTableSorting: [],
    selectAll: false,

    isModalWindowForgetByIdListOpened: false,
    isModalWindowForgetSingleOpened: false,
  };

  static defaultPageOptions = {
    offset: 0,
    limit: LIMIT_LOAD_DATA,
  };

  static wasDataScopeSwitched(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
      || !_isEqual(prevProps.currentOrganization, props.currentOrganization);
  }

  constructor(props) {
    super(props);
    this.state = {
      dataWithSelect: [],
      isActionDisabled: true,
      ...UsersManager.initState,
    };
  }

  componentDidMount() {
    this.props.loadGroups();
    this.props.getPersonasNames();
  }

  componentDidUpdate(prevProps, prevState) {
    // eslint-disable-next-line no-shadow
    const {resetUsersManagerData, loadGroups, getPersonasNames} = this.props;
    if (UsersManager.wasDataScopeSwitched(prevProps, this.props)) {
      resetUsersManagerData();
      this.setState({...UsersManager.initState});
      loadGroups();
      getPersonasNames();
      this.reloadUsers(UsersManager.defaultPageOptions);
    }
    if (this.state.dataWithSelect !== prevState.dataWithSelect) {
      this.setState((prevStateLocal) => {
        const filteredDataWithSelect = prevStateLocal.dataWithSelect
          .filter((entry) => entry.isSelected);
        return ({
          isActionDisabled: filteredDataWithSelect.length === 0,
        });
      });
    }
  }

  componentWillUnmount() {
    this.props.resetUsersManagerData();
  }

  getSearchOptionsWithSorting = (searchOptions, tableSorting) => {
    const sorting = tableSorting.map(({id, desc}) => {
      if (desc) {return `${id} desc`;}
      return id;
    });
    return {
      ...searchOptions,
      sorting,
      ...UsersManager.defaultPageOptions,
    };
  };

  goToUserList = () => {
    this.setState({...UsersManager.initState});
    this.props.resetSelectedUserIndex();
  };

  getContainerItemByModeShowingContainer = (modeShowingContainer) => {
    const {selectedUser} = this.props;
    let containerItem = null;
    switch (modeShowingContainer) {
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN:
        containerItem = null;
        break;
      case MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS:
        containerItem = (
          <UserDetails
            user={selectedUser}
            handleClickButtonBack={this.goToUserList}
            handleForgetUser={() => this.showModalForgetSingleUser(true)}
          />
        );
        break;
      default:
        break;
    }
    return containerItem;
  };

  onFilterChange = () => {
    const {searchOptions} = this.props;
    const {prevTableSorting} = this.state;
    this.setState({prevSearchOptions: searchOptions});
    this.reloadUsers(this.getSearchOptionsWithSorting(searchOptions, prevTableSorting));
  };

  onResetFilters = () => {
    this.props.resetFilters();
    this.setState({prevSearchOptions: UsersManager.initState.prevSearchOptions});
    const pageOptions = this.getSearchOptionsWithSorting(
      UsersManager.initState.prevSearchOptions,
      this.state.prevTableSorting,
    );
    this.reloadUsers(pageOptions);
  };

  onTableStateChange = (tableState) => {
    const {
      users,
      isLastUsersPage,
      loadMoreUsers,
    } = this.props;
    const {prevSearchOptions, prevTableSorting} = this.state;
    const eventType = _get(tableState, 'event.type', false);
    if (eventType !== 'scroll') {
      const tableSorting = _get(tableState, 'sorted', []);
      this.setState({prevTableSorting: tableSorting});
      const pageOptions = this.getSearchOptionsWithSorting(prevSearchOptions, tableSorting);
      this.reloadUsers(pageOptions);
    } else if (!isLastUsersPage) {
      const pageOptions = this.getSearchOptionsWithSorting(prevSearchOptions, prevTableSorting);
      loadMoreUsers(
        {
          ...pageOptions,
          offset: users.length,
          limit: LIMIT_LOAD_DATA,
        },
        I18n.t('users.usersManager.roamingLabel'),
      );
    }
  };

  reloadUsers = (searchOptions) =>
    this.props.loadUsers(
      searchOptions,
      I18n.t('users.usersManager.roamingLabel'),
    );

  showUserDetails = (userIndex) => {
    const {users, getDataToShowUserDetails} = this.props;
    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_AND_ENTITY_DETAILS,
      tableMode: COMPRESSED,
    });
    getDataToShowUserDetails(
      users[userIndex].id,
      userIndex,
      I18n.t('users.usersManager.roamingLabel'),
      users[userIndex].service,
    );
  };

  showModalForgetSingleUser = (show) => {
    this.setState({
      isModalWindowForgetSingleOpened: show,
    });
  };

  forgetSingleUser = () => {
    const {searchOptions, selectedUser, forgetUserById} = this.props;
    forgetUserById(selectedUser.id, selectedUser.service)
      .then((success) => {
        if (success) {
          this.reloadUsers(searchOptions)
            .then(() => {
              this.goToUserList();
            });
        }
      });
    this.showModalForgetByIdList(false);
  };

  showModalForgetByIdList = (show) => {
    this.setState({
      isModalWindowForgetByIdListOpened: show,
    });
  };

  forgetByIdList = () => {
    const {dataWithSelect} = this.state;
    const {searchOptions, forgetUsersByIdList} = this.props;

    const idList = dataWithSelect
      .filter((item) => item.isSelected)
      .map((user) => ({
        id: user.id,
        type: user.service,
      }));

    forgetUsersByIdList(idList)
      .then((success) => {
        if (success) {
          this.reloadUsers(searchOptions)
            .then(() => {
              this.goToUserList();
            });
        }
      });

    this.showModalForgetByIdList(false);
  };

  handleChangeDataWithSelect = (data) => {
    this.setState({dataWithSelect: data});
  };

  handleChangeSelectAll = (selectAll) => {
    this.setState({selectAll});
  };

  render() {
    const {users, selectedUserIndex, isEditMode} = this.props;
    const {
      modeShowingContainer,
      tableMode,
      dataWithSelect,
      selectAll,
      isActionDisabled,
    } = this.state;
    // eslint-disable-next-line max-len
    const isFullScreenList = modeShowingContainer === MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM.SHOW_LIST_FULL_SCREEN;
    const tableClasses = {
      tbody: classNames(
        {usersTable__tbodyInfinite_noControls: !isFullScreenList},
      ),
    };
    const containerClasses = {
      // list_compressed: classes.list_compressed,
      // form: classes.form,
    };

    return (
      <ContainerWithListAndForm
        classes={containerClasses}
        list={(
          <div>
            <RenderOrEmpty isShow={isFullScreenList}>
              <UsersControls
                onRefresh={this.onFilterChange}
                onReset={this.onResetFilters}
              />
            </RenderOrEmpty>
            <UsersTable
              tableData={users}
              dataWithSelect={dataWithSelect}
              selectAll={selectAll}
              customClassNames={tableClasses}
              minRows={MIN_TABLE_ROWS}
              isEditMode={isEditMode}
              isActionDisabled={isActionDisabled}
              mode={tableMode}
              selectedUserIndex={selectedUserIndex}
              loadData={this.onTableStateChange}
              handleClickRow={this.showUserDetails}
              handleChangeDataWithSelect={this.handleChangeDataWithSelect}
              handleChangeSelectAll={this.handleChangeSelectAll}
              handleForgetUsersByIdList={() => this.showModalForgetByIdList(true)}
            />
            <RenderOrEmpty isShow={this.state.isModalWindowForgetByIdListOpened}>
              <DeleteEntityWithCustomButtonsModalWindow
                text={I18n.t('modalWindow.forgetUsersByIdList')}
                confirmText={I18n.t('modalWindow.forgetUsersByIdListButtonConfirm')}
                cancel={() => this.showModalForgetByIdList(false)}
                confirm={() => this.forgetByIdList()}
              />
            </RenderOrEmpty>
            <RenderOrEmpty isShow={this.state.isModalWindowForgetSingleOpened}>
              <DeleteEntityWithCustomButtonsModalWindow
                text={I18n.t('modalWindow.forgetUser')}
                confirmText={I18n.t('modalWindow.forgetUsersByIdListButtonConfirm')}
                cancel={() => this.showModalForgetSingleUser(false)}
                confirm={this.forgetSingleUser}
              />
            </RenderOrEmpty>
          </div>
        )}
        form={this.getContainerItemByModeShowingContainer(modeShowingContainer)}
      />
    );
  }
}

UsersManager.propTypes = {
  classes: PropTypes.object.isRequired,
  loggedAccount: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,
  users: PropTypes.array.isRequired,
  isLastUsersPage: PropTypes.bool.isRequired,
  searchOptions: PropTypes.object.isRequired,
  selectedUser: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  selectedUserIndex: PropTypes.number,

  loadGroups: PropTypes.func.isRequired,
  getPersonasNames: PropTypes.func.isRequired,
  loadUsers: PropTypes.func.isRequired,
  loadMoreUsers: PropTypes.func.isRequired,
  setSearchOption: PropTypes.func.isRequired,
  resetUsersManagerData: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  getDataToShowUserDetails: PropTypes.func.isRequired,
  resetSelectedUserIndex: PropTypes.func.isRequired,
  forgetUsersByIdList: PropTypes.func.isRequired,
  forgetUserById: PropTypes.func.isRequired,
};

UsersManager.defaultProps = {
  currentOrganization: null,
  selectedUserIndex: null,
};

const mapStateToProps = (state) => ({
  loggedAccount: state.userAccountsReducer.loggedAccount,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,

  users: state.usersManagerReducer.tableData,
  isLastUsersPage: state.usersManagerReducer.isLastUsersPage,
  searchOptions: state.usersManagerReducer.searchOptions,
  selectedUser: state.usersManagerReducer.selectedUser,
  selectedUserIndex: state.usersManagerReducer.selectedUserIndex,
  isEditMode: state.usersManagerReducer.isEditMode,
});

const mapDispatchToProps = (dispatch) => ({
  loadGroups: bindActionCreators(loadGroups, dispatch),
  getPersonasNames: bindActionCreators(getPersonasNames, dispatch),
  loadUsers: bindActionCreators(loadUsers, dispatch),
  loadMoreUsers: bindActionCreators(loadMoreUsers, dispatch),
  setSearchOption: bindActionCreators(setSearchOption, dispatch),
  resetUsersManagerData: bindActionCreators(resetUsersManagerData, dispatch),
  resetFilters: bindActionCreators(resetFilters, dispatch),
  getADUserInfo: bindActionCreators(getADUserInfo, dispatch),
  getDataToShowUserDetails: bindActionCreators(getDataToShowUserDetails, dispatch),
  resetSelectedUserIndex: bindActionCreators(resetSelectedUserIndex, dispatch),
  forgetUsersByIdList: bindActionCreators(forgetUsersByIdList, dispatch),
  forgetUserById: bindActionCreators(forgetUserById, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(UsersManager));
