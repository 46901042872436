/* eslint-disable max-len */
export const LINKS = {
  BILLING: 'https://billing.churchdns.com/',
  NEW_REQUEST_TO_SUPPORT: 'https://help.scoutdns.com/portal/newticket',
  CONTACT_SALES: 'mailto:sales@scoutdns.com',
  KNOWLEDGE_BASE: 'https://help.scoutdns.com/portal/kb',
  OPEN_TICKET: 'https://help.scoutdns.com/portal/newticket',
  SETUP_GUIDE: 'https://help.scoutdns.com/portal/kb/articles/quick',
  SYSTEM_STATUS: 'http://status.scoutdns.com/',
  DOWNLOAD_ROOT_CERTIFICATE: '/assets/root-certificate/ScoutDNS-root-cert.crt',
  IMPORT_ROOT_CERTIFICATE_INSTRUCTIONS: 'https://help.scoutdns.com/portal/kb/articles/browser-https-errors-install-scoutdns-certificate',
  MFA_HELP: 'https://help.scoutdns.com/portal/en/newticket',
  ENTRA_ADMIN_CONSENT_URL: 'https://login.microsoftonline.com/',
  ENTRA_COMMON_TENANT: 'common',
  ENTRA_ADMIN_CONSENT_ENDPOINT: 'adminconsent',
  ENTRA_OPENID_CONFIG_PATH: '.well-known/openid-configuration',
};

export const TOP_CATEGORIES_COUNT = 5;
export const TOP_DOMAINS_COUNT = 5;

export const ALLOWED = 'ALLOWED';
export const FORBIDDEN = 'FORBIDDEN';
export const DROP = 'DROP';

export const STRATEGY_STATUSES = {
  TLD_FILTER: 'TLD Filter',
};

export const MANAGEMENT_TYPES = {
  SCOUT_DNS: 'SCOUT_DNS',
  OEM: 'OEM',
};

export const COLORS = {
  ALLOW: 'allow',
  DANGER: 'danger',
  EDIT: 'edit',
  FORBIDDEN: 'forbidden',
  GRAY: 'gray',
  INFO: 'info',
  NOTIFY: 'notify',
  PRIMARY: 'primary',
  SUCCESS: 'success',
  WARNING: 'warning',
  WHITE: 'white',

  DANGER_NO_BACKGROUND: 'dangerNoBackground',
  INFO_NO_BACKGROUND: 'infoNoBackground',
  PRIMARY_NO_BACKGROUND: 'primaryNoBackground',
  SUCCESS_NO_BACKGROUND: 'success',
  WARNING_NO_BACKGROUND: 'warningNoBackground',
};

export const DATE_FORMAT = {
  YYYYMMDD_RIGHT_SLASH: 'YYYY/MM/DD',
  YYYYMMDD_HYPHEN: 'YYYY-MM-DD',
  MMDDYYYY_RIGHT_SLASH: 'MM/DD/YYYY',
  YYYYMMDDTHHmmss: 'YYYY-MM-DDTHH:mm:ss',
  YYYYMMDD_HHmmss: 'YYYY-MM-DD HH:mm:ss',
  YYYYMMDD_HHmmss_z: 'YYYY-MM-DD HH:mm:ss z',
  YYYYMMDDTHHmmss_Z: 'YYYY-MM-DDTHH:mm:ssZ',
  HHmmss_COLON: 'HH:mm:ss',
  TIME_PICKER_LOG_FILTERS: 'h:mm A',
  LOG_FILTER_QUERY_PARAM: 'YYYY-MM-DDTHH:mm',
};

export const FULL_WIDTH_MODE_TABLE = 'FULL_WIDTH_MODE_TABLE';
export const COMPRESSED_MODE_TABLE = 'COMPRESSED_MODE_TABLE';

export const TAGS = {
  GLOBAL_WHITE_BLACK_LIST: 'global',
  READ_ONLY_POLICY: 'readOnly',
  ORGANIZATION_POLICY: 'org',
};

export const AUTH_PROVIDERS = {
  MICROSOFT_ENTRA_ID: 'microsoftEntraID',
};

export const METHODS = {
  ASSIGN_SUPER_ADMIN: 'assignSuperAdmin',
  AUTH: 'auth',
  REFRESH_TOKEN: 'refresh',
  AUTH2FA: 'auth2',
  GET_AUTH_CODE: 'getAuthCode',
  VERIFY_AUTH_CODE: 'verifyAuthCode',
  ADD_DOMAIN_TO_BLACKLIST: 'addDomainToBlacklist',
  ADD_DOMAIN_TO_WHITELIST: 'addDomainToWhitelist',
  ADOPT_RELAY: 'adoptNetworkAgent',
  CANCEL_UNINSTALL_CLIENT: 'cancelUninstallClient',
  CHANGE_PASSWORD: 'changePassword',
  CONFIRM_EMAIL: 'confirmEmailUpdating',
  ASSIGN_BLOCK_PAGE_TO_NETWORK: 'assignBlockPageToNetwork',
  ASSIGN_BLOCK_PAGE_TO_PROFILE: 'assignBlockPageToProfile',
  CREATE_SSO_CONFIGURATION: 'createSSOConfiguration',
  GET_SSO_CONFIGURATIONS: 'getSSOConfigurations',
  DELETE_SSO_CONFIGURATION: 'deleteSSOConfiguration',
  EDIT_SSO_CONFIGURATION: 'editSSOConfiguration',
  CREATE_API_KEY: 'createApiAccessKey',
  CREATE_CAMPUS: 'createLocation',
  CREATE_BLOCK_PAGE: 'createBlockPageV3',
  CREATE_CLIENT_BUNDLE: 'createClientBundle',
  CREATE_CLIENT_LOCAL_FORWARDING: 'createClientLocalForwarding',
  CREATE_CLIENT_PROFILE: 'createClientProfile',
  CREATE_ORGANIZATION: 'createOrganization',
  CREATE_LOCAL_DOMAIN: 'createLocalDomain',
  CREATE_LOCAL_FORWARDING: 'createLocalForwarding',
  CREATE_NETWORK: 'createNetwork',
  CREATE_NOTIFICATION_PROFILE: 'createNotificationProfile',
  CREATE_OPERATOR: 'createOperator',
  CREATE_PERSONA: 'createPersonaV2',
  CREATE_POLICY: 'createPolicy',
  CREATE_SUBNET: 'createSubnet',
  CREATE_WHITE_BLACK_LIST: 'createWhiteBlackList',
  DELETE_CAMPUS: 'deleteLocation',
  DELETE_BLOCK_PAGE: 'deleteBlockPage',
  DELETE_CLIENT_BUNDLE: 'deleteClientBundle',
  DELETE_CLIENT_LOCAL_FORWARDING: 'deleteClientLocalForwarding',
  DELETE_CLIENT_PROFILE: 'deleteClientProfile',
  DELETE_ORGANIZATION: 'deleteOrganization',
  DELETE_LOCAL_DOMAIN: 'deleteLocalDomain',
  DELETE_LOCAL_FORWARDING: 'deleteLocalForwarding',
  DELETE_NETWORK: 'deleteNetwork',
  DELETE_NETWORK_AGENT: 'deleteNetworkAgent',
  DELETE_NOTIFICATION_PROFILE: 'deleteNotificationProfile',
  DELETE_PERSONA: 'deletePersona',
  DELETE_POLICY: 'deletePolicy',
  DELETE_SUBNET: 'deleteSubnet',
  DELETE_WHITE_BLACK_LIST: 'deleteWhiteBlackList',
  DISABLE_TOKEN: 'disableToken',
  EXPORT_USAGE_BILLING_STATS: 'exportUsageBillingStats',
  EXPORT_CLIENTS: 'exportClients',
  GET_ACCOUNT_INFO: 'getAccountInfo',
  GET_ALL_TLDS: 'getAllTopLevelDomains',
  GET_API_KEYS: 'getApiAccessKeys',
  GET_AUDIT_CSV: 'getAuditCsv',
  GET_AUDIT_JSON: 'getAuditJson',
  GET_AUDIT_REPORT: 'reports',
  GET_AVERAGE_LATENCY: 'getAverageLatency',
  GET_CAMPUS_BY_ID: 'getLocationById',
  GET_CAMPUSES: 'getLocations',
  GET_BLOCK_PAGES: 'getBlockPagesV3',
  GET_BLOCK_PAGE_BY_ID: 'getBlockPageByIdV3',
  GET_DEFAULT_BLOCK_PAGE: 'getDefaultBlockPage',
  GET_CAMPUSES_STATS: 'getLocationsStats',
  GET_CATEGORY_GROUPS_STATS: 'getCategoryGroupsStats',
  GET_CATEGORY_STATISTICS: 'getCategoryStatistics',
  GET_CLIENT_BUNDLES: 'getClientBundles',
  GET_CLIENT_BUNDLE_BY_ID: 'getClientBundleById',
  GET_CLIENT_BY_ID: 'getClientById',
  GET_CLIENT_COUNT_STATS: 'getClientCountStats',
  GET_CLIENT_LOCAL_DOMAINS: 'getClientLocalDomains',
  GET_CLIENT_LOCAL_FORWARDING_BY_ID: 'getClientLocalForwardingById',
  GET_CLIENT_NAMES: 'getClientNames',
  GET_CLIENT_PROFILE_NAMES: 'getClientProfileNames',
  GET_CLIENT_PROFILE_BY_ID: 'getClientProfileById',
  GET_CLIENT_PROFILE_POLICIES: 'getClientProfilePolicies',
  GET_CLIENT_PROFILES: 'getClientProfiles',
  GET_CLIENT_SESSIONS: 'getClientSessions',
  GET_CLIENT_THREATS: 'getThreatStats',
  GET_CLIENTS: 'getClients',
  GET_CLIENTS_TOTAL: 'getClientsCount',
  FORGET_CLIENTS_BY_ID_LIST: 'deleteClientsByIdList',
  FORGET_CLIENT: 'deleteClient',
  DISABLE_CLIENTS_BY_ID_LIST: 'disableClientsByIdList',
  DISABLE_CLIENT: 'disableClient',
  ENABLE_CLIENTS_BY_ID_LIST: 'enableClientsByIdList',
  ENABLE_CLIENT: 'enableClient',
  UNINSTALL_CLIENTS_BY_ID_LIST: 'uninstallClientsByIdList',
  UNINSTALL_CLIENT: 'uninstallClient',
  GET_CLIENT_STATS: 'getClientStats',
  GET_ORGS_AND_SITES_STATS: 'getOrgsAndSitesStats',
  GET_ORGANIZATION_BY_ID: 'getOrganizationById',
  GET_ORGANIZATIONS: 'getOrganizations',
  GET_USAGE_BILLING_STATS: 'getUsageBillingStats',
  GET_ORGANIZATION_NAMES: 'getOrganizationNames',
  GET_ORGANIZATION_CLIENT_PROFILE_STATS: 'getOrganizationClientProfileStats',
  GET_ORGANIZATION_NETWORK_STATS: 'getOrganizationNetworkStats',
  GET_ORGANIZATION_LOCATIONS: 'getOrganizationLocations',
  GET_ORGANIZATION_LOCATION_STATS: 'getOrganizationLocationStats',
  GET_ORGANIZATION_NETWORK_STATS_BY_LOCATION: 'getOrganizationNetworkStatsByLocation',
  GET_ORGANIZATION_CLIENT_PROFILES: 'getOrganizationClientProfiles',
  GET_ORGANIZATION_CLIENTS_STATS: 'getOrganizationClientsStats',
  GET_ORGANIZATION_PERSONAS: 'getOrganizationPersonas',
  GET_PERFORMANCE_STATS: 'getPerformanceStats',
  GET_INSIGHTS_LOGS: 'getStatisticsLogs',
  GET_FILTER_RECORDS: 'getFilterRecords',
  GET_FIRST_AND_LAST_NAME: 'getFirstAndLastName',
  GET_IPs: 'getIPs',
  GET_LOCAL_DOMAIN_BY_ID: 'getLocalDomainById',
  GET_LOCAL_DOMAINS: 'getLocalDomains',
  GET_LOCAL_FORWARDING_BY_ID: 'getLocalForwardingById',
  GET_LOCAL_FORWARDINGS: 'getLocalForwardings',
  GET_LOCATION_POLICIES: 'getLocationPolicies',
  GET_NETWORK_BY_ID: 'getNetworkById',
  GET_NETWORKS: 'getNetworks',
  GET_NETWORKS_BY: 'getNetworksBy',
  GET_NETWORKS_COUNT: 'getNetworksCount',
  GET_NETWORKS_STATS: 'getNetworksStats',
  GET_NETWORKS_STATUS: 'getNetworksStatus',
  GET_NETWORK_AGENTS: 'getNetworkAgents',
  GET_NOTIFICATION_PROFILES: 'getNotificationProfiles',
  GET_NOTIFICATION_TYPES: 'getNotificationTypes',
  GET_OPERATORS: 'getAccountOperators',
  GET_PLATFORMS: 'getClientPlatforms',
  GET_CLIENT_INSTALLERS: 'getUniversalInstallers',
  GET_PERSONAS_WITH_USER_STATS: 'getPersonas',
  GET_PERSONAS_NAMES: 'getPersonasNames',
  GET_PERSONA_AND_DOMAIN_GROUPS: 'getPersonaAndDomainGroupsV2',
  GET_ENTRA_TENANTS: 'getEntraTenants',
  GET_ENTRA_TENANT_BY_ID: 'getEntraTenantById',
  GET_PERSONA_POLICIES: 'getPersonaPolicies',
  GET_POLICIES: 'getPolicies',
  GET_POLICIES_BY_TLD_FILTER: 'getPoliciesByTldFilter',
  GET_POLICIES_BY_WB_LIST: 'getPoliciesByWBList',
  GET_POLICY_BY_ID: 'getPolicyById',
  GET_POLICY_TEMPLATE: 'getPolicyTemplate',
  GET_RELAY_BINARIES: 'getNetworkAgentBuildList',
  GET_REQUESTS_PER_NETWORKS: 'getRequestsPerNetwork',
  GET_RESPONSE_DETAILS: 'getAuditResponse',
  GET_REQUESTS_PER_SECONDS: 'getRequestsPerSecond',
  GET_REQUEST_STATS_BY_DECISIONS: 'getRequestStatsByDecisions',
  GET_REQUEST_STATS_BY_CATEGORIES: 'getTopCategoriesGraph',
  GET_SUBNET_BY_ID: 'getSubnetById',
  GET_SUBNETS: 'getSubnets',
  GET_TOP_5_CATEGORIES: 'getTop5CategoriesV2',
  GET_TOP_5_DOMAINS: 'getTop5ParentDomains',
  GET_TOP_LEVEL_DOMAIN_STATISTICS: 'getTopLevelDomainStatistics',
  GET_TOTAL_REQUESTS_BY_DECISION: 'getTotalRequestsByDecision',
  GET_USER_ACCOUNTS: 'getUserAccounts',
  GET_USER_ORGANIZATIONS: 'getUserOrganizations',
  GET_USER_INFO: 'getUserInfo',
  GET_USER_REQUESTS_GRAPH: 'getUserRequestsGraph',
  GET_AD_USER_SESSIONS: 'getAdUserClientSessions',
  GET_USER_GROUP_NAMES: 'getADGroupNames',
  GET_ENTRA_GROUPS_AND_SYNC_TIME: 'getEntraGroupsAndSyncTime',
  GET_ENTRA_USER_SESSIONS: 'getEntraUserClientSessions',
  GET_USERS: 'getADEntraUsers',
  GET_WHITE_BLACK_LIST_BY_ID: 'getWhiteBlackListById',
  GET_AD_USER_INFO: 'getADUserInfo',
  GET_AD_DOMAINS: 'getADDomains',
  GET_ENTRA_USER_INFO: 'getEntraUserInfo',
  GET_FALLBACK_DNS_SETTINGS: 'getFallbackDnsSettings',
  GET_UPSTREAM_SERVICES: 'getDohUpstreamServices',
  FORGET_USER_BY_ID: 'deleteAdEntraUser',
  FORGET_USERS_BY_ID_LIST: 'deleteAdEntraUsers',
  MANAGE_MFA: 'manageMfa',
  REMOVE_SITE_FROM_ORGANIZATION: 'removeLocationFromOrganization',
  REMOVE_CLIENT_PROFILE_FROM_ORGANIZATION: 'removeClientProfileFromOrganization',
  REMOVE_PERSONA_FROM_ORGANIZATION: 'removePersonaFromOrganization',
  RESET_PASSWORD: 'getEmailWithPassToken',
  REVOKE_API_KEY: 'deleteApiAccessKey',
  REVOKE_OPERATOR_ACCESS: 'revokeOperatorAccess',
  RESEND_AUTH_CODE: 'resendAuthCode',
  SWITCH_ACCOUNT: 'switchAccount',
  SWITCH_ORGANIZATION: 'switchOrganization',
  SYNC_ENTRA_GROUPS: 'syncEntraData',
  UNSUBSCRIBE_EMAIL_FROM_NOTIFICATION: 'unsubscribeEmailFromNotification',
  UPLOAD_BLOCK_PAGE_LOGO: 'uploadBlockPageLogo',
  UPDATE_BLOCK_PAGE: 'editBlockPageV3',
  UPDATE_API_KEY: 'editApiAccessKey',
  UPDATE_CAMPUS: 'editLocation',
  UPDATE_CLIENT: 'editClient',
  UPDATE_CLIENT_BUNDLE: 'editClientBundle',
  UPDATE_CLIENT_LOCAL_FORWARDING: 'editClientLocalForwarding',
  UPDATE_CLIENT_PROFILE: 'editClientProfile',
  UPDATE_EMAIL: 'updateUserEmail',
  UPDATE_FIRST_AND_LAST_NAME: 'updateFirstAndLastName',
  UPDATE_ORGANIZATION: 'editOrganization',
  UPDATE_LOCAL_DOMAIN: 'editLocalDomain',
  UPDATE_LOCAL_FORWARDING: 'editLocalForwarding',
  UPDATE_NETWORK: 'editNetwork',
  UPDATE_NETWORK_AGENT_DATA: 'updateNetworkAgentData',
  UPDATE_NOTIFICATION_PROFILE: 'updateNotificationProfile',
  UPDATE_OPERATOR: 'updateOperator',
  UPDATE_ORGANIZATION_LOCATIONS: 'updateOrganizationLocations',
  UPDATE_ORGANIZATION_PERSONAS: 'updateOrganizationClientPersonas',
  UPDATE_ORGANIZATION_PROFILES: 'updateOrganizationClientProfiles',
  UPDATE_PASSWORD_BY_TOKEN: 'updatePasswordByToken',
  UPDATE_PERSONA: 'updatePersonaV2',
  UPDATE_POLICY: 'editPolicy',
  UPDATE_SUBNET: 'editSubnet',
  UPDATE_WHITE_BLACK_LIST: 'editWhiteBlackList',
  UPDATE_RELAY_FALLBACK_DNS: 'upsertFallbackDnsSettings',
  GET_INVOICES: 'getInvoices',
  GET_INVOICE_URL: 'getInvoiceUrl',
  GET_SUBSCRIPTION_DETAILS: 'getSubscriptionDetails',
  GET_DOMAIN_STATISTICS: 'getDomainStatisticsV2',
  GET_FQDN_STATISTICS: 'getFqdnStatistics',
  GET_RECORD_TYPE_STATISTICS: 'getRecordTypeStatistics',
  OAUTH_SSO: 'oauth-sso',
  ADD_ENTRA_TENANT: 'addEntraTenant',
};

export const METHODS_WITH_NO_TOKEN_REFRESH_ON_FAILURE = [
  METHODS.OAUTH_SSO,
  METHODS.REFRESH_TOKEN,
];

export const NETWORK_STATUSES = {
  RED: 'Red',
  BLACK: 'Black',
  YELLOW: 'Yellow',
  GREEN: 'Green',
};

export const RELAY_STATUSES = {
  ONLINE: 'ONLINE',
  PROBABLY_OFFLINE: 'PROBABLY_OFFLINE',
  OFFLINE: 'OFFLINE',
  UPDATING_CONFIG: 'UPDATING_CONFIG',
  NOT_ADOPTED: 'NOT_ADOPTED',
  ENABLING: 'ENABLING',
  DISABLING: 'DISABLING',
  ADOPTING: 'ADOPTING',
};

export const CLIENT_STATUSES = {
  ONLINE: 'DEVICE_AGENT_ONLINE',
  OFFLINE: 'DEVICE_AGENT_OFFLINE',
  UNINSTALL: 'DEVICE_AGENT_UNINSTALL',
  DISABLED: 'DEVICE_AGENT_DISABLED',
  MISSING: 'DEVICE_AGENT_MISSING',
};

export const USER_STATUSES = {
  ONLINE: 'USER_ONLINE',
  OFFLINE: 'USER_OFFLINE',
  MISSING: 'USER_MISSING',
};

export const RELAY_STATUS_INDICATOR_MODES = {
  FULL: 'FULL',
  COMPACT: 'COMPACT',
};

export const SUBNET_STATUSES = {
  RED: 'Red',
  BLACK: 'Black',
  YELLOW: 'Yellow',
  GREEN: 'Green',
};

export const PERIODS = {
  LAST_HOUR: 'Last Hour',
  TODAY: 'Today',
  LAST_24_HOURS: 'Last 24 Hours',
  YESTERDAY: 'Yesterday',
  THIS_WEEK: 'This Week',
  LAST_WEEK: 'Last Week',
  THIS_MONTH: 'This Month',
  LAST_MONTH: 'Last 30 Days',
};

export const INSIGHTS_PERIODS = {
  LAST_HOUR: 'Last Hour',
  TODAY: 'Today',
  LAST_24_HOURS: 'Last 24 Hours',
  YESTERDAY: 'Yesterday',
  THIS_WEEK: 'This Week',
  LAST_WEEK: 'Last Week',
  LAST_7_DAYS: 'Last 7 Days',
  THIS_MONTH: 'This Month',
  LAST_MONTH: 'Last 30 Days',
};

export const MODES_SHOWING_CONTAINER_WITH_LIST_AND_FORM = {
  SHOW_LIST_FULL_SCREEN: 'SHOW_LIST_FULL_SCREEN',
  SHOW_LIST_AND_ENTITY_DETAILS: 'SHOW_LIST_AND_ENTITY_DETAILS',
  SHOW_LIST_AND_CREATING_FORM: 'SHOW_LIST_AND_CREATING_FORM',
  SHOW_FORM_FULL_SCREEN: 'SHOW_FORM_FULL_SCREEN',
};

export const MODES_SHOWING_CONTAINER_WITH_FORM = {
  SHOW_CREATING_FORM_FULL_SCREEN: 'SHOW_CREATING_FORM_FULL_SCREEN',
  SHOW_ENTITY_DETAILS_FULL_SCREEN: 'SHOW_ENTITY_DETAILS_FULL_SCREEN',
};

export const CATEGORIES = {
  THREATS: 'Threats',
  CONTENT: 'Content',
  APPLICATION: 'Applications',
  WHITE_BLACK_LIST: 'White/Black List',
};

export const CATEGORY_TYPES = {
  THREATS: 'Threats',
  CONTENT: 'Content',
  APPLICATION: 'Applications',
};

export const CATEGORY_STATUSES = {
  ALLOWED: 'ALLOWED',
  FORBIDDEN: 'FORBIDDEN',
};

export const TLD_TYPES = {
  GENERIC: 'gTLD',
  RESTRICTED_GENERIC: 'grTLD',
  SPONSORED: 'sTLD',
  COUNTRY_CODE: 'ccTLD',
  INFRASTRUCTURE: 'infrastructure',
  TEST: 'test',
};

export const TLD_TYPES_WITHOUT_INFRASTRUCTURE = {
  GENERIC: 'gTLD',
  RESTRICTED_GENERIC: 'grTLD',
  SPONSORED: 'sTLD',
  COUNTRY_CODE: 'ccTLD',
  TEST: 'test',
};

export const FILTER_TYPES = {
  CATEGORY: 'Category',
  DOMAIN_LENGTH: 'Domain length',
  FAIL: 'Fail',
  SPY_MODE: 'Spy mode',
  SAFE_SEARCH: 'Safe search',
  WHITE_BLACK_LIST: 'White/Black list',
};

export const DECISIONS = {
  ALLOWED: 'ALLOWED',
  FORBIDDEN: 'FORBIDDEN',
  DROP: 'DROP',
};

export const INSIGHTS_DECISIONS = {
  ALLOWED: DECISIONS.ALLOWED,
  FORBIDDEN: DECISIONS.FORBIDDEN,
};

export const MOVE_TO_PERMIT_LIST_OPTIONS = {
  ALL: 'ALL',
  TOP_25: 'TOP_25',
  TOP_50: 'TOP_50',
  TOP_100: 'TOP_100',
};

export const DEFAULT_POLICIES = {
  NO_POLICY: 'No Policy',
  HIGH: 'High',
  MODERATE: 'Moderate',
  LOW: 'Low',
};

export const POLICY_FORM_COMPONENTS_TYPES = {
  CHECKBOX: 'checkbox',
  INPUT: 'input',
  DROPDOWN: 'dropdown',
  MULTI_DROPDOWN: 'multiDropdown',
};

export const STATES_ENTITY = {
  CANCELED: 'CANCELED',
  TURN_OFF: 'TURN_OFF',
  TURN_ON: 'TURN_ON',
  TURN_ON_TO_CREATE: 'TURN_ON_TO_CREATE',
  CREATING: 'CREATING',
  EDITING: 'EDITING',
  EDITING_CANCELED: 'EDITING_CANCELED',
  NONE: 'NONE',
};

export const REG_EXR = {
  // TODO: fix regex - it doesn't match with regexp on the backend because lookbehind is not supported in JS
  WHITE_BLACK_LIST_DOMAIN: /^(\*(?=\.)|[A-Za-z0-9-]{1,63})((\.)?(?!-)[A-Za-z0-9-]{1,63})*\.[A-Za-z](-?[A-Za-z]){1,62}$/,
  DOMAIN_SPLIT_PARAM: /\s*[\n,;]\s*/,
  EMAIL: /^.+@.+$/,
  IP_V4: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  IP_V6: /^(([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))$/,
  IP_V4_CIDR: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/(12[0-8]|[1-9][0-9]|[0-9]))?$/,
  IP_V6_CIDR: /^(([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))(\/(12[0-8]|[1-9][0-9]|[0-9]))?$/,
  POSITIVE_INT: /^[1-9]\d*$/,
  NON_NEGATIVE_REAL: /^\d+(\.\d+)?$/,
  UUIDV4: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
  URL: /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/,
};

export const ROUTES = {
  CAMPUS: '/site',
  DASHBOARD: '/dashboard',
  DEFAULT: '/',
  IPs_LIST: '/ip-list',
  LOG: '/log',
  LOG_AUDIT: '/log-audit/:filename',
  BLOCK_PAGE: '/block-page',
  LOGIN: '/login',
  LOGOUT_SSO: '/logout/sso/',
  LOGIN_SSO: '/login/sso/*',
  LOGIN_SSO_MULTITENANT: '/login/sso/multitenant',
  NO_ACCOUNTS: '/no-accounts',
  POLICY: '/policy',
  RESET_PASSWORD: '/reset-password',
  ROOT_CERTIFICATE: '/root-certificate',
  SUBSCRIPTION: '/subscription',
  UPDATE_PASSWORD: '/update-password/:token',
  WHITE_BLACK_LIST: '/white-black-list',
  CUSTOM_LISTS: '/custom-lists',
  ACCESS_MANAGEMENT: '/access-management',
  API_ACCESS_MANAGEMENT: '/api-access-management',
  PROFILE: '/profile',
  CONFIRM_EMAIL: '/confirm-email',
  NOTIFICATIONS_MANAGEMENT: '/notifications-management',
  UNSUBSCRIBE_EMAIL_FROM_NOTIFICATION: '/unsubscribe-email-from-notification',
  CANCELLED: '/cancelled-account',
  INSIGHTS: '/insights',
  PRINT_RESPONSE: '/print-response',
  DOWNLOAD_RELAY: '/download-relay',
  CLIENTS: '/clients',
  ORGANIZATIONS: '/organizations',
  USERS: '/users',
  USERS_CONFIGURE: '/users/configure',
};

export const VALID_PASSWORD_LENGTH = 6;
export const MAX_FIRST_OR_LAST_NAME_LENGTH = 256;
export const DEFAULT_MAX_INPUT_STRING_LENGTH = 1000;

export const POLICY_FORM_ITEMS = {
  CATEGORY: {
    type: 'checkbox',
    name: '',
    values: [
      {
        name: 'FORBIDDEN',
        id: null,
      },
      {
        name: 'ALLOWED',
        id: null,
      },
      {
        name: 'NOTHING',
        id: null,
      },
    ],
    value: 0,
  },
};

export const MODAL_WINDOW_NOTIFY_TYPE = {
  INFO: 'info',
  ERROR: 'error',
};

export const FETCHER_ERRORS = {
  SYNTAX: 'SyntaxError',
  ABORTED: 'AbortError',
};

export const USER_ROLES = {
  SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  ADMIN: 'ROLE_ADMIN',
  VIEWER: 'ROLE_VIEWER',
  ORGANIZATION_OPERATOR: 'ROLE_ORGANIZATION_OPERATOR',
  OEM_SUPER_ADMIN: 'ROLE_OEM_SUPER_ADMIN',
  OEM_ADMIN: 'ROLE_OEM_ADMIN',
  PROJECT_VIEWER: 'ROLE_PROJECT_VIEWER',
  SERVICE_DESK: 'ROLE_SERVICE_DESK',
};

export const AUTHENTICATION_PROVIDER_TYPES = {
  SCOUT_DNS: 'SCOUT_DNS',
  AZURE: 'AZURE',
};

export const ORGANIZATION_OPERATOR_PERMISSIONS = {
  MANAGER: 'MANAGER',
  VIEWER: 'VIEWER',
};

export const NON_SUPER_ADMIN_USER_ROLES = {
  ADMIN: 'ROLE_ADMIN',
  VIEWER: 'ROLE_VIEWER',
  ORGANIZATION_OPERATOR: 'ROLE_ORGANIZATION_OPERATOR',
  PROJECT_VIEWER: 'ROLE_PROJECT_VIEWER',
  SERVICE_DESK: 'ROLE_SERVICE_DESK',
};

export const NON_SUPER_ADMIN_OEM_USER_ROLES = {
  OEM_ADMIN: 'ROLE_OEM_ADMIN',
};

const ACCOUNT_TYPES = {
  TEST: 'test',
  TRIAL: 'trial',
  SUBSCRIBER: 'subscriber',
};

const ACCOUNT_STATUSES = {
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
  DISABLED: 'disabled',
  EXPIRED: 'expired',
  CANCELLED: 'cancelled',
};

export const ROOT_CERTIFICATE_FILE_NAME = 'ScoutDNS Cert.crt';

export const PAGES = {
  LOGIN: 'login',
  RESET_PASSWORD: 'resetPassword',
  UPDATE_PASSWORD: 'updatePassword',
  CONFIRM_EMAIL: 'confirmEmail',
  UNSUBSCRIBE_EMAIL_FROM_NOTIFICATION: 'unsubscribeEmailFromNotification',
  NO_ACCOUNTS: 'noAccounts',
  DASHBOARD: 'dashboard',
  CAMPUS: 'site',
  POLICY: 'policy',
  CUSTOM_LISTS: 'customLists',
  LOG: 'log',
  LOG_AUDIT: 'logAudit',
  IPS_LIST: 'ipsList',
  ROOT_CERTIFICATE: 'rootCertificate',
  DOWNLOAD_RELAY: 'downloadRelay',
  SUBSCRIPTION: 'subscription',
  ACCESS_MANAGEMENT: 'accessManagement',
  API_ACCESS_MANAGEMENT: 'apiAccessManagement',
  PROFILE: 'profile',
  NOTIFICATION_MANAGEMENT: 'notificationsManagement',
  BLOCK_PAGE: 'blockPage',
  INSIGHTS: 'insights',
  CLIENTS: 'clients',
  USERS: 'users',
  ORGANIZATIONS: 'organizations',
  PRINT_RESPONSE: 'printResponse',
  CANCELLED: 'cancelled',
};

const DEFAULT_ROLES_AVAILABILITY = {
  [USER_ROLES.SUPER_ADMIN]: true,
  [USER_ROLES.ADMIN]: true,
  [USER_ROLES.VIEWER]: true,
  [USER_ROLES.PROJECT_VIEWER]: true,
  [USER_ROLES.SERVICE_DESK]: true,
  [USER_ROLES.ORGANIZATION_OPERATOR]: true,
  [USER_ROLES.OEM_SUPER_ADMIN]: true,
  [USER_ROLES.OEM_ADMIN]: true,
};

export const ROLES_AVAILABILITY = {
  [PAGES.ACCESS_MANAGEMENT]: {
    [USER_ROLES.VIEWER]: false,
    [USER_ROLES.SERVICE_DESK]: false,
    [USER_ROLES.PROJECT_VIEWER]: false,
    [USER_ROLES.ORGANIZATION_OPERATOR]: false,
  },
  [PAGES.API_ACCESS_MANAGEMENT]: {
    [USER_ROLES.VIEWER]: false,
    [USER_ROLES.SERVICE_DESK]: false,
    [USER_ROLES.PROJECT_VIEWER]: false,
    [USER_ROLES.ORGANIZATION_OPERATOR]: false,
  },
  [PAGES.NOTIFICATION_MANAGEMENT]: {
    [USER_ROLES.VIEWER]: false,
    [USER_ROLES.SERVICE_DESK]: false,
    [USER_ROLES.PROJECT_VIEWER]: false,
    [USER_ROLES.ORGANIZATION_OPERATOR]: false,
  },
  [PAGES.ORGANIZATIONS]: {
    [USER_ROLES.ORGANIZATION_OPERATOR]: false,
  },
  [PAGES.SUBSCRIPTION]: {
    [USER_ROLES.VIEWER]: false,
    [USER_ROLES.SERVICE_DESK]: false,
    [USER_ROLES.PROJECT_VIEWER]: false,
    [USER_ROLES.ORGANIZATION_OPERATOR]: false,
  },
  [PAGES.BLOCK_PAGE]: {
    [USER_ROLES.ORGANIZATION_OPERATOR]: false,
    [USER_ROLES.VIEWER]: false,
    [USER_ROLES.SERVICE_DESK]: false,
    [USER_ROLES.PROJECT_VIEWER]: false,
  },
  promoteNewSuperAdmin: {
    [USER_ROLES.VIEWER]: false,
    [USER_ROLES.SERVICE_DESK]: false,
    [USER_ROLES.PROJECT_VIEWER]: false,
    [USER_ROLES.ADMIN]: false,
    [USER_ROLES.ORGANIZATION_OPERATOR]: false,
    [USER_ROLES.OEM_ADMIN]: false,
    [USER_ROLES.OEM_SUPER_ADMIN]: false,
  },
  configureSSO: {
    [USER_ROLES.VIEWER]: false,
    [USER_ROLES.SERVICE_DESK]: false,
    [USER_ROLES.PROJECT_VIEWER]: false,
    [USER_ROLES.ADMIN]: false,
    [USER_ROLES.ORGANIZATION_OPERATOR]: false,
    [USER_ROLES.OEM_ADMIN]: false,
    [USER_ROLES.OEM_SUPER_ADMIN]: false,
    [USER_ROLES.SUPER_ADMIN]: true,
  },
  enableMFA: {
    [USER_ROLES.VIEWER]: false,
    [USER_ROLES.SERVICE_DESK]: false,
    [USER_ROLES.PROJECT_VIEWER]: false,
    [USER_ROLES.ADMIN]: false,
    [USER_ROLES.ORGANIZATION_OPERATOR]: false,
  },
  viewAllOrganizations: {
    ...DEFAULT_ROLES_AVAILABILITY,
    [USER_ROLES.ORGANIZATION_OPERATOR]: false,
  },
};

const DEFAULT_ACCOUNT_TYPE_AVAILABILITY = {
  [ACCOUNT_TYPES.TEST]: true,
  [ACCOUNT_TYPES.TRIAL]: true,
  [ACCOUNT_TYPES.SUBSCRIBER]: true,
};

export const ACCOUNT_TYPE_AVAILABILITY = {};

const DEFAULT_ACCOUNT_STATUS_AVAILABILITY = {
  [ACCOUNT_STATUSES.ACTIVE]: true,
  [ACCOUNT_STATUSES.SUSPENDED]: false,
  [ACCOUNT_STATUSES.DISABLED]: false,
  [ACCOUNT_STATUSES.EXPIRED]: false,
  [ACCOUNT_STATUSES.CANCELLED]: false,
};

export const ACCOUNT_STATUS_AVAILABILITY = {
  [PAGES.SUBSCRIPTION]: {
    [ACCOUNT_STATUSES.SUSPENDED]: true,
    [ACCOUNT_STATUSES.EXPIRED]: true,
  },
  [PAGES.CANCELLED]: {
    [ACCOUNT_STATUSES.ACTIVE]: false,
    [ACCOUNT_STATUSES.CANCELLED]: true,
  },
};

export const ENTITY_OPENING_MODE = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
};

export const ACCESS_RIGHTS = {
  CREATE: 'create',
  EDIT: 'edit',
  DELETE: 'delete',

  SITE_CREATE: 'site.create',
  SITE_EDIT: 'site.edit',
  SITE_DELETE: 'site.delete',
  SITE_WAN_SEE: 'site.wan.see',
  SITE_WAN_CREATE: 'site.wan.create',
  SITE_WAN_EDIT: 'site.wan.edit',
  SITE_WAN_DELETE: 'site.wan.delete',
  SITE_LAN_SEE: 'site.lan.see',
  SITE_LAN_CREATE: 'site.lan.create',
  SITE_LAN_EDIT: 'site.lan.edit',
  SITE_LAN_DELETE: 'site.lan.delete',
  SITE_LOCAL_FORWARD_SEE: 'site.localForward.see',
  SITE_LOCAL_FORWARD_CREATE: 'site.localForward.create',
  SITE_LOCAL_FORWARD_EDIT: 'site.localForward.edit',
  SITE_LOCAL_FORWARD_DELETE: 'site.localForward.delete',
  SITE_REDIRECTS_SEE: 'site.redirects.see',
  SITE_REDIRECTS_CREATE: 'site.redirects.create',
  SITE_REDIRECTS_EDIT: 'site.redirects.edit',
  SITE_REDIRECTS_DELETE: 'site.redirects.delete',
  SITE_RELAYS_SEE: 'site.relays.see',
  SITE_RELAYS_CREATE: 'site.relays.create',
  SITE_RELAYS_EDIT: 'site.relays.edit',
  SITE_RELAYS_DELETE: 'site.relays.delete',
  SITE_RELAYS_ADOPT: 'site.relays.adopt',
  SITE_RELAYS_FORGET: 'site.relays.forget',
  SITE_INFO_SEE: 'site.info.see',
  SITE_DASHBOARD_ALLOWED_BLOCKED_SEE: 'sites.dashboard.allowedBlocked.see',
  SITE_FALLBACK_CREATE: 'site.fallbackDNS.create',
  SITE_FALLBACK_EDIT: 'site.fallbackDNS.edit',
  SITE_FALLBACK_DELETE: 'site.fallbackDNS.delete',

  BLOCK_PAGE_CREATE: 'blockPage.create',
  BLOCK_PAGE_DELETE: 'blockPage.delete',
  BLOCK_PAGE_EDIT: 'blockPage.edit',
  BLOCK_PAGE_SEE: 'blockPage.see',

  SINGLE_SIGN_ON_CONFIGURATION_DELETE: 'singleSignOnConfiguration.delete',
  SINGLE_SIGN_ON_CONFIGURATION_EDIT: 'singleSignOnConfiguration.edit',

  HELP_AND_SUPPORT_DOWNLOAD_RELAY_SEE: 'helpAndSupport.downloadRelay.see',
  HELP_AND_SUPPORT_IP_LIST_SEE: 'helpAndSupport.ipList.see',
  HELP_AND_SUPPORT_KNOWLEDGE_BASE_SEE: 'helpAndSupport.knowledgeBase.see',
  HELP_AND_SUPPORT_SYSTEM_STATUS_SEE: 'helpAndSupport.systemStatus.see',
  HELP_AND_SUPPORT_SETUP_GUIDE_SEE: 'helpAndSupport.setupGuide.see',
  HELP_AND_SUPPORT_ROOT_CERTIFICATE_SEE: 'helpAndSupport.rootCertificate.see',
  HELP_AND_SUPPORT_OPEN_TICKET_SEE: 'helpAndSupport.openTicket.see',

  PERSONA_CREATE: 'persona.create',
  PERSONA_EDIT: 'persona.edit',
  PERSONA_DELETE: 'persona.delete',
  PERSONA_BIND: 'persona.bind',

  POLICY_CREATE: 'policy.create',
  POLICY_COPY: 'policy.copy',
  POLICY_EDIT: 'policy.edit',
  POLICY_DELETE: 'policy.delete',
  POLICY_EDIT__ORG: 'policy.edit:org',
  POLICY_DELETE__ORG: 'policy.delete:org',

  CLIENT_EDIT: 'client.edit',
  CLIENT_FORGET: 'client.forget',
  CLIENT_DISABLE_OR_ENABLE: 'client.disableOrEnable',
  CLIENT_UNINSTALL_OR_CANCEL: 'client.uninstallOrCancel',
  CLIENT_PROFILE_CREATE: 'client.profile.create',
  CLIENT_PROFILE_EDIT: 'client.profile.edit',
  CLIENT_PROFILE_DELETE: 'client.profile.delete',
  CLIENT_PROFILE_LOCAL_FORWARDING_CREATE: 'client.profile.localForwarding.create',
  CLIENT_PROFILE_LOCAL_FORWARDING_EDIT: 'client.profile.localForwarding.edit',
  CLIENT_PROFILE_LOCAL_FORWARDING_DELETE: 'client.profile.localForwarding.delete',
  CLIENT_PROFILE_INSTALLKEY_CREATE: 'client.profile.installKey.create',
  CLIENT_PROFILE_INSTALLKEY_EDIT: 'client.profile.installKey.edit',
  CLIENT_PROFILE_INSTALLKEY_DELETE: 'client.profile.installKey.delete',

  ORGANIZATION_CREATE: 'organization.create',
  ORGANIZATION_EDIT: 'organization.edit',
  ORGANIZATION_DELETE: 'organization.delete',
  ORGANIZATION_SITE_LINK: 'organization.site.link',
  ORGANIZATION_PROFILE_LINK: 'organization.profile.link',
  ORGANIZATION_PERSONA_LINK: 'organization.persona.link',

  CUSTOM_LIST_CREATE: 'customList.create',
  CUSTOM_LIST_EDIT: 'customList.edit',
  CUSTOM_LIST_DELETE: 'customList.delete',
  CUSTOM_LIST_MAKE_GLOBAL: 'customList.makeGlobal',
  CUSTOM_LIST_EDIT__ORG: 'customList.edit:org',
  CUSTOM_LIST_DELETE__ORG: 'customList.delete:org',

  CUSTOM_LIST_TLD_CREATE: 'customList.tld.create',
  CUSTOM_LIST_TLD_EDIT: 'customList.tld.edit',
  CUSTOM_LIST_TLD_DELETE: 'customList.tld.delete',
  CUSTOM_LIST_TLD_EDIT__ORG: 'customList.tld.edit:org',
  CUSTOM_LIST_TLD_DELETE__ORG: 'customList.tld.delete:org',

  USER_PROFILE_GENERAL_INFO_EDIT: 'userProfile.generalInfo.edit',
  USER_PROFILE_EMAIL_EDIT: 'userProfile.email.edit',

  ACCESS_MANAGEMENT_OPERATOR_CREATE: 'accessManagement.operator.create',
  ACCESS_MANAGEMENT_OPERATOR_EDIT: 'accessManagement.operator.edit',
  ACCESS_MANAGEMENT_OPERATOR_REVOKE_ACCESS: 'accessManagement.operator.revokeAccess',

  API_ACCESS_MANAGEMENT_KEY_CREATE: 'apiAccessManagement.key.create',
  API_ACCESS_MANAGEMENT_KEY_EDIT: 'apiAccessManagement.key.edit',
  API_ACCESS_MANAGEMENT_KEY_REVOKE: 'apiAccessManagement.key.revokeAccess',

  NOTIFICATION_MANAGEMENT_PROFILE_CREATE: 'notificationManagement.profile.create',
  NOTIFICATION_MANAGEMENT_PROFILE_EDIT: 'notificationManagement.profile.edit',
  NOTIFICATION_MANAGEMENT_PROFILE_DELETE: 'notificationManagement.profile.delete',

  USER_FORGET: 'user.forget',
  USER_EDIT: 'user.edit',
};

export const ACCESS_RIGHTS_MAP_BY_ROLE_AND_PERMISSION = {
  [USER_ROLES.SUPER_ADMIN]: {
    [ORGANIZATION_OPERATOR_PERMISSIONS.MANAGER]: [
      ACCESS_RIGHTS.SITE_CREATE,
      ACCESS_RIGHTS.SITE_EDIT,
      ACCESS_RIGHTS.SITE_DELETE,
      ACCESS_RIGHTS.SITE_WAN_SEE,
      ACCESS_RIGHTS.SITE_WAN_CREATE,
      ACCESS_RIGHTS.SITE_WAN_EDIT,
      ACCESS_RIGHTS.SITE_WAN_DELETE,
      ACCESS_RIGHTS.SITE_LAN_SEE,
      ACCESS_RIGHTS.SITE_LAN_CREATE,
      ACCESS_RIGHTS.SITE_LAN_EDIT,
      ACCESS_RIGHTS.SITE_LAN_DELETE,
      ACCESS_RIGHTS.SITE_LOCAL_FORWARD_SEE,
      ACCESS_RIGHTS.SITE_LOCAL_FORWARD_CREATE,
      ACCESS_RIGHTS.SITE_LOCAL_FORWARD_EDIT,
      ACCESS_RIGHTS.SITE_LOCAL_FORWARD_DELETE,
      ACCESS_RIGHTS.SITE_REDIRECTS_SEE,
      ACCESS_RIGHTS.SITE_REDIRECTS_CREATE,
      ACCESS_RIGHTS.SITE_REDIRECTS_EDIT,
      ACCESS_RIGHTS.SITE_REDIRECTS_DELETE,
      ACCESS_RIGHTS.SITE_RELAYS_SEE,
      ACCESS_RIGHTS.SITE_RELAYS_CREATE,
      ACCESS_RIGHTS.SITE_RELAYS_EDIT,
      ACCESS_RIGHTS.SITE_RELAYS_DELETE,
      ACCESS_RIGHTS.SITE_RELAYS_ADOPT,
      ACCESS_RIGHTS.SITE_RELAYS_FORGET,
      ACCESS_RIGHTS.SITE_INFO_SEE,
      ACCESS_RIGHTS.SITE_DASHBOARD_ALLOWED_BLOCKED_SEE,

      ACCESS_RIGHTS.BLOCK_PAGE_CREATE,
      ACCESS_RIGHTS.BLOCK_PAGE_DELETE,
      ACCESS_RIGHTS.BLOCK_PAGE_EDIT,

      ACCESS_RIGHTS.SINGLE_SIGN_ON_CONFIGURATION_DELETE,
      ACCESS_RIGHTS.SINGLE_SIGN_ON_CONFIGURATION_EDIT,

      ACCESS_RIGHTS.HELP_AND_SUPPORT_DOWNLOAD_RELAY_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_IP_LIST_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_KNOWLEDGE_BASE_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_SYSTEM_STATUS_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_SETUP_GUIDE_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_ROOT_CERTIFICATE_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_OPEN_TICKET_SEE,

      ACCESS_RIGHTS.PERSONA_CREATE,
      ACCESS_RIGHTS.PERSONA_EDIT,
      ACCESS_RIGHTS.PERSONA_DELETE,
      ACCESS_RIGHTS.PERSONA_BIND,

      ACCESS_RIGHTS.POLICY_CREATE,
      ACCESS_RIGHTS.POLICY_EDIT,
      ACCESS_RIGHTS.POLICY_COPY,
      ACCESS_RIGHTS.POLICY_DELETE,
      ACCESS_RIGHTS.POLICY_EDIT__ORG,
      ACCESS_RIGHTS.POLICY_DELETE__ORG,

      ACCESS_RIGHTS.CLIENT_EDIT,
      ACCESS_RIGHTS.CLIENT_FORGET,
      ACCESS_RIGHTS.CLIENT_DISABLE_OR_ENABLE,
      ACCESS_RIGHTS.CLIENT_UNINSTALL_OR_CANCEL,
      ACCESS_RIGHTS.CLIENT_PROFILE_CREATE,
      ACCESS_RIGHTS.CLIENT_PROFILE_EDIT,
      ACCESS_RIGHTS.CLIENT_PROFILE_DELETE,
      ACCESS_RIGHTS.CLIENT_PROFILE_LOCAL_FORWARDING_CREATE,
      ACCESS_RIGHTS.CLIENT_PROFILE_LOCAL_FORWARDING_EDIT,
      ACCESS_RIGHTS.CLIENT_PROFILE_LOCAL_FORWARDING_DELETE,
      ACCESS_RIGHTS.CLIENT_PROFILE_INSTALLKEY_CREATE,
      ACCESS_RIGHTS.CLIENT_PROFILE_INSTALLKEY_EDIT,
      ACCESS_RIGHTS.CLIENT_PROFILE_INSTALLKEY_DELETE,

      ACCESS_RIGHTS.ORGANIZATION_CREATE,
      ACCESS_RIGHTS.ORGANIZATION_EDIT,
      ACCESS_RIGHTS.ORGANIZATION_DELETE,
      ACCESS_RIGHTS.ORGANIZATION_SITE_LINK,
      ACCESS_RIGHTS.ORGANIZATION_PROFILE_LINK,
      ACCESS_RIGHTS.ORGANIZATION_PERSONA_LINK,

      ACCESS_RIGHTS.CUSTOM_LIST_CREATE,
      ACCESS_RIGHTS.CUSTOM_LIST_EDIT,
      ACCESS_RIGHTS.CUSTOM_LIST_DELETE,
      ACCESS_RIGHTS.CUSTOM_LIST_MAKE_GLOBAL,
      ACCESS_RIGHTS.CUSTOM_LIST_EDIT__ORG,
      ACCESS_RIGHTS.CUSTOM_LIST_DELETE__ORG,
      ACCESS_RIGHTS.CUSTOM_LIST_TLD_CREATE,
      ACCESS_RIGHTS.CUSTOM_LIST_TLD_EDIT,
      ACCESS_RIGHTS.CUSTOM_LIST_TLD_DELETE,
      ACCESS_RIGHTS.CUSTOM_LIST_TLD_EDIT__ORG,
      ACCESS_RIGHTS.CUSTOM_LIST_TLD_DELETE__ORG,

      ACCESS_RIGHTS.USER_PROFILE_GENERAL_INFO_EDIT,
      ACCESS_RIGHTS.USER_PROFILE_EMAIL_EDIT,

      ACCESS_RIGHTS.ACCESS_MANAGEMENT_OPERATOR_CREATE,
      ACCESS_RIGHTS.ACCESS_MANAGEMENT_OPERATOR_EDIT,
      ACCESS_RIGHTS.ACCESS_MANAGEMENT_OPERATOR_REVOKE_ACCESS,

      ACCESS_RIGHTS.API_ACCESS_MANAGEMENT_KEY_CREATE,
      ACCESS_RIGHTS.API_ACCESS_MANAGEMENT_KEY_EDIT,
      ACCESS_RIGHTS.API_ACCESS_MANAGEMENT_KEY_REVOKE,

      ACCESS_RIGHTS.NOTIFICATION_MANAGEMENT_PROFILE_CREATE,
      ACCESS_RIGHTS.NOTIFICATION_MANAGEMENT_PROFILE_EDIT,
      ACCESS_RIGHTS.NOTIFICATION_MANAGEMENT_PROFILE_DELETE,

      ACCESS_RIGHTS.USER_FORGET,
      ACCESS_RIGHTS.USER_EDIT,

      ACCESS_RIGHTS.SITE_FALLBACK_CREATE,
      ACCESS_RIGHTS.SITE_FALLBACK_DELETE,
      ACCESS_RIGHTS.SITE_FALLBACK_EDIT,
    ],
  },
  [USER_ROLES.ADMIN]: {
    [ORGANIZATION_OPERATOR_PERMISSIONS.MANAGER]: [
      ACCESS_RIGHTS.SITE_CREATE,
      ACCESS_RIGHTS.SITE_EDIT,
      ACCESS_RIGHTS.SITE_DELETE,
      ACCESS_RIGHTS.SITE_WAN_SEE,
      ACCESS_RIGHTS.SITE_WAN_CREATE,
      ACCESS_RIGHTS.SITE_WAN_EDIT,
      ACCESS_RIGHTS.SITE_WAN_DELETE,
      ACCESS_RIGHTS.SITE_LAN_SEE,
      ACCESS_RIGHTS.SITE_LAN_CREATE,
      ACCESS_RIGHTS.SITE_LAN_EDIT,
      ACCESS_RIGHTS.SITE_LAN_DELETE,
      ACCESS_RIGHTS.SITE_LOCAL_FORWARD_SEE,
      ACCESS_RIGHTS.SITE_LOCAL_FORWARD_CREATE,
      ACCESS_RIGHTS.SITE_LOCAL_FORWARD_EDIT,
      ACCESS_RIGHTS.SITE_LOCAL_FORWARD_DELETE,
      ACCESS_RIGHTS.SITE_REDIRECTS_SEE,
      ACCESS_RIGHTS.SITE_REDIRECTS_CREATE,
      ACCESS_RIGHTS.SITE_REDIRECTS_EDIT,
      ACCESS_RIGHTS.SITE_REDIRECTS_DELETE,
      ACCESS_RIGHTS.SITE_RELAYS_SEE,
      ACCESS_RIGHTS.SITE_RELAYS_CREATE,
      ACCESS_RIGHTS.SITE_RELAYS_EDIT,
      ACCESS_RIGHTS.SITE_RELAYS_DELETE,
      ACCESS_RIGHTS.SITE_RELAYS_ADOPT,
      ACCESS_RIGHTS.SITE_RELAYS_FORGET,
      ACCESS_RIGHTS.SITE_INFO_SEE,
      ACCESS_RIGHTS.SITE_DASHBOARD_ALLOWED_BLOCKED_SEE,

      ACCESS_RIGHTS.BLOCK_PAGE_CREATE,
      ACCESS_RIGHTS.BLOCK_PAGE_DELETE,
      ACCESS_RIGHTS.BLOCK_PAGE_EDIT,
      ACCESS_RIGHTS.PERSONA_BIND,

      ACCESS_RIGHTS.HELP_AND_SUPPORT_DOWNLOAD_RELAY_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_IP_LIST_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_KNOWLEDGE_BASE_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_SYSTEM_STATUS_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_SETUP_GUIDE_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_ROOT_CERTIFICATE_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_OPEN_TICKET_SEE,

      ACCESS_RIGHTS.PERSONA_CREATE,
      ACCESS_RIGHTS.PERSONA_EDIT,
      ACCESS_RIGHTS.PERSONA_DELETE,

      ACCESS_RIGHTS.POLICY_CREATE,
      ACCESS_RIGHTS.POLICY_EDIT,
      ACCESS_RIGHTS.POLICY_COPY,
      ACCESS_RIGHTS.POLICY_DELETE,
      ACCESS_RIGHTS.POLICY_EDIT__ORG,
      ACCESS_RIGHTS.POLICY_DELETE__ORG,

      ACCESS_RIGHTS.CLIENT_EDIT,
      ACCESS_RIGHTS.CLIENT_FORGET,
      ACCESS_RIGHTS.CLIENT_DISABLE_OR_ENABLE,
      ACCESS_RIGHTS.CLIENT_UNINSTALL_OR_CANCEL,
      ACCESS_RIGHTS.CLIENT_PROFILE_CREATE,
      ACCESS_RIGHTS.CLIENT_PROFILE_EDIT,
      ACCESS_RIGHTS.CLIENT_PROFILE_DELETE,
      ACCESS_RIGHTS.CLIENT_PROFILE_LOCAL_FORWARDING_CREATE,
      ACCESS_RIGHTS.CLIENT_PROFILE_LOCAL_FORWARDING_EDIT,
      ACCESS_RIGHTS.CLIENT_PROFILE_LOCAL_FORWARDING_DELETE,
      ACCESS_RIGHTS.CLIENT_PROFILE_INSTALLKEY_CREATE,
      ACCESS_RIGHTS.CLIENT_PROFILE_INSTALLKEY_EDIT,
      ACCESS_RIGHTS.CLIENT_PROFILE_INSTALLKEY_DELETE,

      ACCESS_RIGHTS.ORGANIZATION_CREATE,
      ACCESS_RIGHTS.ORGANIZATION_EDIT,
      ACCESS_RIGHTS.ORGANIZATION_DELETE,
      ACCESS_RIGHTS.ORGANIZATION_SITE_LINK,
      ACCESS_RIGHTS.ORGANIZATION_PROFILE_LINK,
      ACCESS_RIGHTS.ORGANIZATION_PERSONA_LINK,

      ACCESS_RIGHTS.CUSTOM_LIST_CREATE,
      ACCESS_RIGHTS.CUSTOM_LIST_EDIT,
      ACCESS_RIGHTS.CUSTOM_LIST_DELETE,
      ACCESS_RIGHTS.CUSTOM_LIST_MAKE_GLOBAL,
      ACCESS_RIGHTS.CUSTOM_LIST_EDIT__ORG,
      ACCESS_RIGHTS.CUSTOM_LIST_DELETE__ORG,
      ACCESS_RIGHTS.CUSTOM_LIST_TLD_CREATE,
      ACCESS_RIGHTS.CUSTOM_LIST_TLD_EDIT,
      ACCESS_RIGHTS.CUSTOM_LIST_TLD_DELETE,
      ACCESS_RIGHTS.CUSTOM_LIST_TLD_EDIT__ORG,
      ACCESS_RIGHTS.CUSTOM_LIST_TLD_DELETE__ORG,

      ACCESS_RIGHTS.USER_PROFILE_GENERAL_INFO_EDIT,
      ACCESS_RIGHTS.USER_PROFILE_EMAIL_EDIT,

      ACCESS_RIGHTS.ACCESS_MANAGEMENT_OPERATOR_CREATE,
      ACCESS_RIGHTS.ACCESS_MANAGEMENT_OPERATOR_EDIT,
      ACCESS_RIGHTS.ACCESS_MANAGEMENT_OPERATOR_REVOKE_ACCESS,

      ACCESS_RIGHTS.API_ACCESS_MANAGEMENT_KEY_CREATE,
      ACCESS_RIGHTS.API_ACCESS_MANAGEMENT_KEY_EDIT,
      ACCESS_RIGHTS.API_ACCESS_MANAGEMENT_KEY_REVOKE,

      ACCESS_RIGHTS.NOTIFICATION_MANAGEMENT_PROFILE_CREATE,
      ACCESS_RIGHTS.NOTIFICATION_MANAGEMENT_PROFILE_EDIT,
      ACCESS_RIGHTS.NOTIFICATION_MANAGEMENT_PROFILE_DELETE,

      ACCESS_RIGHTS.USER_FORGET,
      ACCESS_RIGHTS.USER_EDIT,

      ACCESS_RIGHTS.SITE_FALLBACK_CREATE,
      ACCESS_RIGHTS.SITE_FALLBACK_DELETE,
      ACCESS_RIGHTS.SITE_FALLBACK_EDIT,
    ],
  },
  [USER_ROLES.VIEWER]: {
    [ORGANIZATION_OPERATOR_PERMISSIONS.VIEWER]: [
      ACCESS_RIGHTS.USER_PROFILE_GENERAL_INFO_EDIT,
      ACCESS_RIGHTS.USER_PROFILE_EMAIL_EDIT,
      ACCESS_RIGHTS.SITE_WAN_SEE,
      ACCESS_RIGHTS.SITE_LAN_SEE,
      ACCESS_RIGHTS.SITE_LOCAL_FORWARD_SEE,
      ACCESS_RIGHTS.SITE_REDIRECTS_SEE,
      ACCESS_RIGHTS.SITE_RELAYS_SEE,
      ACCESS_RIGHTS.SITE_INFO_SEE,
      ACCESS_RIGHTS.SITE_DASHBOARD_ALLOWED_BLOCKED_SEE,

      ACCESS_RIGHTS.HELP_AND_SUPPORT_DOWNLOAD_RELAY_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_IP_LIST_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_KNOWLEDGE_BASE_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_SYSTEM_STATUS_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_SETUP_GUIDE_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_ROOT_CERTIFICATE_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_OPEN_TICKET_SEE,
    ],
  },
  [USER_ROLES.SERVICE_DESK]: {
    [ORGANIZATION_OPERATOR_PERMISSIONS.MANAGER]: [
      ACCESS_RIGHTS.USER_PROFILE_GENERAL_INFO_EDIT,
      ACCESS_RIGHTS.USER_PROFILE_EMAIL_EDIT,
      ACCESS_RIGHTS.SITE_WAN_SEE,
      ACCESS_RIGHTS.SITE_LAN_SEE,
      ACCESS_RIGHTS.SITE_LOCAL_FORWARD_SEE,
      ACCESS_RIGHTS.SITE_REDIRECTS_SEE,
      ACCESS_RIGHTS.SITE_RELAYS_SEE,
      ACCESS_RIGHTS.SITE_INFO_SEE,
      ACCESS_RIGHTS.SITE_DASHBOARD_ALLOWED_BLOCKED_SEE,

      ACCESS_RIGHTS.HELP_AND_SUPPORT_DOWNLOAD_RELAY_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_IP_LIST_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_KNOWLEDGE_BASE_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_SYSTEM_STATUS_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_SETUP_GUIDE_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_ROOT_CERTIFICATE_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_OPEN_TICKET_SEE,

      ACCESS_RIGHTS.CUSTOM_LIST_EDIT,
      ACCESS_RIGHTS.CUSTOM_LIST_TLD_EDIT,
      ACCESS_RIGHTS.CUSTOM_LIST_EDIT__ORG,
    ],
    [ORGANIZATION_OPERATOR_PERMISSIONS.VIEWER]: [
      ACCESS_RIGHTS.USER_PROFILE_GENERAL_INFO_EDIT,
      ACCESS_RIGHTS.USER_PROFILE_EMAIL_EDIT,
      ACCESS_RIGHTS.SITE_WAN_SEE,
      ACCESS_RIGHTS.SITE_LAN_SEE,
      ACCESS_RIGHTS.SITE_LOCAL_FORWARD_SEE,
      ACCESS_RIGHTS.SITE_REDIRECTS_SEE,
      ACCESS_RIGHTS.SITE_RELAYS_SEE,
      ACCESS_RIGHTS.SITE_INFO_SEE,
      ACCESS_RIGHTS.SITE_DASHBOARD_ALLOWED_BLOCKED_SEE,

      ACCESS_RIGHTS.HELP_AND_SUPPORT_DOWNLOAD_RELAY_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_IP_LIST_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_KNOWLEDGE_BASE_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_SYSTEM_STATUS_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_SETUP_GUIDE_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_ROOT_CERTIFICATE_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_OPEN_TICKET_SEE,

      ACCESS_RIGHTS.CUSTOM_LIST_EDIT,
      ACCESS_RIGHTS.CUSTOM_LIST_TLD_EDIT,
      ACCESS_RIGHTS.CUSTOM_LIST_EDIT__ORG,
    ],
  },
  [USER_ROLES.PROJECT_VIEWER]: {
    [ORGANIZATION_OPERATOR_PERMISSIONS.VIEWER]: [
      ACCESS_RIGHTS.USER_PROFILE_GENERAL_INFO_EDIT,
      ACCESS_RIGHTS.USER_PROFILE_EMAIL_EDIT,
      ACCESS_RIGHTS.SITE_WAN_SEE,
      ACCESS_RIGHTS.SITE_LAN_SEE,
      ACCESS_RIGHTS.SITE_LOCAL_FORWARD_SEE,
      ACCESS_RIGHTS.SITE_REDIRECTS_SEE,
      ACCESS_RIGHTS.SITE_RELAYS_SEE,
      ACCESS_RIGHTS.SITE_INFO_SEE,
      ACCESS_RIGHTS.SITE_DASHBOARD_ALLOWED_BLOCKED_SEE,

      ACCESS_RIGHTS.HELP_AND_SUPPORT_DOWNLOAD_RELAY_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_IP_LIST_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_KNOWLEDGE_BASE_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_SYSTEM_STATUS_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_SETUP_GUIDE_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_ROOT_CERTIFICATE_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_OPEN_TICKET_SEE,
    ],
  },
  [USER_ROLES.ORGANIZATION_OPERATOR]: {
    [ORGANIZATION_OPERATOR_PERMISSIONS.VIEWER]: [
      ACCESS_RIGHTS.USER_PROFILE_GENERAL_INFO_EDIT,
      ACCESS_RIGHTS.USER_PROFILE_EMAIL_EDIT,
      ACCESS_RIGHTS.SITE_WAN_SEE,
      ACCESS_RIGHTS.SITE_LAN_SEE,
      ACCESS_RIGHTS.SITE_LOCAL_FORWARD_SEE,
      ACCESS_RIGHTS.SITE_REDIRECTS_SEE,
      ACCESS_RIGHTS.SITE_RELAYS_SEE,
      ACCESS_RIGHTS.SITE_INFO_SEE,
      ACCESS_RIGHTS.SITE_DASHBOARD_ALLOWED_BLOCKED_SEE,

      ACCESS_RIGHTS.HELP_AND_SUPPORT_DOWNLOAD_RELAY_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_IP_LIST_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_KNOWLEDGE_BASE_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_SYSTEM_STATUS_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_SETUP_GUIDE_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_ROOT_CERTIFICATE_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_OPEN_TICKET_SEE,
    ],
    [ORGANIZATION_OPERATOR_PERMISSIONS.MANAGER]: [
      ACCESS_RIGHTS.SITE_CREATE,
      ACCESS_RIGHTS.SITE_EDIT,
      ACCESS_RIGHTS.SITE_DELETE,
      ACCESS_RIGHTS.SITE_LAN_SEE,
      ACCESS_RIGHTS.SITE_WAN_SEE,
      ACCESS_RIGHTS.SITE_WAN_CREATE,
      ACCESS_RIGHTS.SITE_WAN_EDIT,
      ACCESS_RIGHTS.SITE_WAN_DELETE,
      ACCESS_RIGHTS.SITE_LAN_SEE,
      ACCESS_RIGHTS.SITE_LAN_CREATE,
      ACCESS_RIGHTS.SITE_LAN_EDIT,
      ACCESS_RIGHTS.SITE_LAN_DELETE,
      ACCESS_RIGHTS.SITE_LOCAL_FORWARD_SEE,
      ACCESS_RIGHTS.SITE_LOCAL_FORWARD_CREATE,
      ACCESS_RIGHTS.SITE_LOCAL_FORWARD_EDIT,
      ACCESS_RIGHTS.SITE_LOCAL_FORWARD_DELETE,
      ACCESS_RIGHTS.SITE_REDIRECTS_SEE,
      ACCESS_RIGHTS.SITE_REDIRECTS_CREATE,
      ACCESS_RIGHTS.SITE_REDIRECTS_EDIT,
      ACCESS_RIGHTS.SITE_REDIRECTS_DELETE,
      ACCESS_RIGHTS.SITE_RELAYS_SEE,
      ACCESS_RIGHTS.SITE_RELAYS_CREATE,
      ACCESS_RIGHTS.SITE_RELAYS_EDIT,
      ACCESS_RIGHTS.SITE_RELAYS_DELETE,
      ACCESS_RIGHTS.SITE_RELAYS_ADOPT,
      ACCESS_RIGHTS.SITE_RELAYS_FORGET,
      ACCESS_RIGHTS.SITE_INFO_SEE,
      ACCESS_RIGHTS.SITE_DASHBOARD_ALLOWED_BLOCKED_SEE,

      ACCESS_RIGHTS.BLOCK_PAGE_CREATE,
      ACCESS_RIGHTS.BLOCK_PAGE_DELETE,
      ACCESS_RIGHTS.BLOCK_PAGE_EDIT,

      ACCESS_RIGHTS.HELP_AND_SUPPORT_DOWNLOAD_RELAY_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_IP_LIST_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_KNOWLEDGE_BASE_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_SYSTEM_STATUS_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_SETUP_GUIDE_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_ROOT_CERTIFICATE_SEE,
      ACCESS_RIGHTS.HELP_AND_SUPPORT_OPEN_TICKET_SEE,

      ACCESS_RIGHTS.PERSONA_CREATE,
      ACCESS_RIGHTS.PERSONA_EDIT,
      ACCESS_RIGHTS.PERSONA_DELETE,
      ACCESS_RIGHTS.PERSONA_BIND,

      ACCESS_RIGHTS.POLICY_CREATE,
      ACCESS_RIGHTS.POLICY_COPY,
      ACCESS_RIGHTS.POLICY_EDIT__ORG,
      ACCESS_RIGHTS.POLICY_DELETE__ORG,

      ACCESS_RIGHTS.CLIENT_EDIT,
      ACCESS_RIGHTS.CLIENT_FORGET,
      ACCESS_RIGHTS.CLIENT_DISABLE_OR_ENABLE,
      ACCESS_RIGHTS.CLIENT_UNINSTALL_OR_CANCEL,
      ACCESS_RIGHTS.CLIENT_PROFILE_CREATE,
      ACCESS_RIGHTS.CLIENT_PROFILE_EDIT,
      ACCESS_RIGHTS.CLIENT_PROFILE_DELETE,
      ACCESS_RIGHTS.CLIENT_PROFILE_LOCAL_FORWARDING_CREATE,
      ACCESS_RIGHTS.CLIENT_PROFILE_LOCAL_FORWARDING_EDIT,
      ACCESS_RIGHTS.CLIENT_PROFILE_LOCAL_FORWARDING_DELETE,
      ACCESS_RIGHTS.CLIENT_PROFILE_INSTALLKEY_CREATE,
      ACCESS_RIGHTS.CLIENT_PROFILE_INSTALLKEY_EDIT,
      ACCESS_RIGHTS.CLIENT_PROFILE_INSTALLKEY_DELETE,

      ACCESS_RIGHTS.USER_PROFILE_GENERAL_INFO_EDIT,
      ACCESS_RIGHTS.USER_PROFILE_EMAIL_EDIT,

      ACCESS_RIGHTS.CUSTOM_LIST_CREATE,
      ACCESS_RIGHTS.CUSTOM_LIST_EDIT__ORG,
      ACCESS_RIGHTS.CUSTOM_LIST_DELETE__ORG,
      ACCESS_RIGHTS.CUSTOM_LIST_TLD_CREATE,
      ACCESS_RIGHTS.CUSTOM_LIST_TLD_EDIT__ORG,
      ACCESS_RIGHTS.CUSTOM_LIST_TLD_DELETE__ORG,

      ACCESS_RIGHTS.USER_FORGET,
      ACCESS_RIGHTS.USER_EDIT,
    ],
  },
  [USER_ROLES.OEM_SUPER_ADMIN]: {
    [ORGANIZATION_OPERATOR_PERMISSIONS.MANAGER]: [
      ACCESS_RIGHTS.SITE_RELAYS_ADOPT,
      ACCESS_RIGHTS.SITE_RELAYS_FORGET,

      ACCESS_RIGHTS.SITE_WAN_SEE,
      ACCESS_RIGHTS.SITE_INFO_SEE,

      ACCESS_RIGHTS.HELP_AND_SUPPORT_OPEN_TICKET_SEE,

      ACCESS_RIGHTS.BLOCK_PAGE_CREATE,
      ACCESS_RIGHTS.BLOCK_PAGE_DELETE,
      ACCESS_RIGHTS.BLOCK_PAGE_EDIT,

      ACCESS_RIGHTS.USER_PROFILE_GENERAL_INFO_EDIT,
      ACCESS_RIGHTS.USER_PROFILE_EMAIL_EDIT,

      ACCESS_RIGHTS.ACCESS_MANAGEMENT_OPERATOR_CREATE,
      ACCESS_RIGHTS.ACCESS_MANAGEMENT_OPERATOR_EDIT,
      ACCESS_RIGHTS.ACCESS_MANAGEMENT_OPERATOR_REVOKE_ACCESS,

      ACCESS_RIGHTS.API_ACCESS_MANAGEMENT_KEY_CREATE,
      ACCESS_RIGHTS.API_ACCESS_MANAGEMENT_KEY_EDIT,
      ACCESS_RIGHTS.API_ACCESS_MANAGEMENT_KEY_REVOKE,

      ACCESS_RIGHTS.NOTIFICATION_MANAGEMENT_PROFILE_CREATE,
      ACCESS_RIGHTS.NOTIFICATION_MANAGEMENT_PROFILE_EDIT,
      ACCESS_RIGHTS.NOTIFICATION_MANAGEMENT_PROFILE_DELETE,
    ],
  },
  [USER_ROLES.OEM_ADMIN]: {
    [ORGANIZATION_OPERATOR_PERMISSIONS.MANAGER]: [
      ACCESS_RIGHTS.SITE_RELAYS_ADOPT,
      ACCESS_RIGHTS.SITE_RELAYS_FORGET,

      ACCESS_RIGHTS.SITE_WAN_SEE,
      ACCESS_RIGHTS.SITE_INFO_SEE,

      ACCESS_RIGHTS.HELP_AND_SUPPORT_OPEN_TICKET_SEE,

      ACCESS_RIGHTS.BLOCK_PAGE_CREATE,
      ACCESS_RIGHTS.BLOCK_PAGE_DELETE,
      ACCESS_RIGHTS.BLOCK_PAGE_EDIT,

      ACCESS_RIGHTS.USER_PROFILE_GENERAL_INFO_EDIT,
      ACCESS_RIGHTS.USER_PROFILE_EMAIL_EDIT,

      ACCESS_RIGHTS.ACCESS_MANAGEMENT_OPERATOR_CREATE,
      ACCESS_RIGHTS.ACCESS_MANAGEMENT_OPERATOR_EDIT,
      ACCESS_RIGHTS.ACCESS_MANAGEMENT_OPERATOR_REVOKE_ACCESS,

      ACCESS_RIGHTS.API_ACCESS_MANAGEMENT_KEY_CREATE,
      ACCESS_RIGHTS.API_ACCESS_MANAGEMENT_KEY_EDIT,
      ACCESS_RIGHTS.API_ACCESS_MANAGEMENT_KEY_REVOKE,

      ACCESS_RIGHTS.NOTIFICATION_MANAGEMENT_PROFILE_CREATE,
      ACCESS_RIGHTS.NOTIFICATION_MANAGEMENT_PROFILE_EDIT,
      ACCESS_RIGHTS.NOTIFICATION_MANAGEMENT_PROFILE_DELETE,
    ],
  },
};

const DEFAULT_PERMISSION_BY_ROLE = {
  [USER_ROLES.SUPER_ADMIN]: ORGANIZATION_OPERATOR_PERMISSIONS.MANAGER,
  [USER_ROLES.ADMIN]: ORGANIZATION_OPERATOR_PERMISSIONS.MANAGER,
  [USER_ROLES.VIEWER]: ORGANIZATION_OPERATOR_PERMISSIONS.VIEWER,
  [USER_ROLES.PROJECT_VIEWER]: ORGANIZATION_OPERATOR_PERMISSIONS.VIEWER,
  [USER_ROLES.ORGANIZATION_OPERATOR]: ORGANIZATION_OPERATOR_PERMISSIONS.VIEWER,
  [USER_ROLES.OEM_SUPER_ADMIN]: ORGANIZATION_OPERATOR_PERMISSIONS.MANAGER,
  [USER_ROLES.OEM_ADMIN]: ORGANIZATION_OPERATOR_PERMISSIONS.MANAGER,
};

export const getDefaultPermissionByRole = (role) => {
  let rightsByRole = DEFAULT_PERMISSION_BY_ROLE[role];
  if (rightsByRole == null) {
    rightsByRole = ORGANIZATION_OPERATOR_PERMISSIONS.VIEWER;
  }
  return rightsByRole;
};

export const getRightsByRoleAndPermission = (role, permission) => {
  const rightsByRole = ACCESS_RIGHTS_MAP_BY_ROLE_AND_PERMISSION[role];

  return rightsByRole[permission];
};

export const isComponentAvailable = (componentName, role, accountStatus, accountType) => {
  const rolesAvailability = {
    ...DEFAULT_ROLES_AVAILABILITY,
    ...ROLES_AVAILABILITY[componentName],
  };
  const statusAvailability = {
    ...DEFAULT_ACCOUNT_STATUS_AVAILABILITY,
    ...ACCOUNT_STATUS_AVAILABILITY[componentName],
  };
  const typeAvailability = {
    ...DEFAULT_ACCOUNT_TYPE_AVAILABILITY,
    ...ACCOUNT_TYPE_AVAILABILITY[componentName],
  };

  return rolesAvailability[role] && statusAvailability[accountStatus] && typeAvailability[accountType];
};

export const DEFAULT_ROUTE_BY_STATUS = {
  [ACCOUNT_STATUSES.SUSPENDED]: ROUTES.SUBSCRIPTION,
  [ACCOUNT_STATUSES.EXPIRED]: ROUTES.SUBSCRIPTION,
  [ACCOUNT_STATUSES.CANCELLED]: ROUTES.CANCELLED,
};

export const MAX_LOCAL_DNS_IPS = 4;

export const getDefaultRouteByStatus = (accountStatus) => DEFAULT_ROUTE_BY_STATUS[accountStatus] || ROUTES.DEFAULT;

export const ENTER_KEY_CODE = 13;

export const OS_NAMES = {
  Windows: 'windows',
  Mac: 'mac',
};

export const DEFAULT_WIDGET_KEY = 'page'; // By default API requests will be in this group

export const TRUST_DEVICE_COOKIE_NAME = 'scout-dns-trust-device';
